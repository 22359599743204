import { useEffect, useState, FC, useContext, Fragment, ChangeEvent } from 'react';
import { postNewAdmin } from '../../store/features/adminSlice';
import snackContext from '../../store/features/contextSnackbar';
import { postNewGroup } from '../../store/features/groupSlice';
import { addGroupMembers } from '../../store/features/memberSlice';
import BlueAddModalLabels from '../../type/modalLabels';
import { UsersIdArray } from '../../type/user';
import { useAppDispatch } from '../../utils/hooks/storeHooks';
import { SearchAutocomplete } from '../searchAutocomplete/SearchAutocomplete';
import AzulInput from '../simpleComponents/AzulInput';
import AzulModal from '../simpleComponents/Modal';
import styles from './BlueAddBtn.module.scss';
import { BlueAddButton } from './StyledBtn';

type BlueAddBtnProps = {
  actionLabel: string;
  cancelActionLabel?: string;
  groupId?: number;
  nameInput?: string; // An input for Name will be rendered with the prop value as label if provided.
};

export const BlueAddBtn: FC<BlueAddBtnProps> = ({
  actionLabel,
  cancelActionLabel,
  groupId,
  nameInput,
}: BlueAddBtnProps) => {
  const dispatch = useAppDispatch();
  const { setSnackPack } = useContext(snackContext);
  // States
  const [open, setOpen] = useState<boolean>(false);
  const [usersIdArr, setUsersIdArr] = useState<UsersIdArray[]>([]);
  const [nameInputValue, setNameInputValue] = useState<string>('');
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState<boolean>(true);

  const getModalLabels = (label: string, nameValue: string) => {
    const labelsData: { [key: string]: BlueAddModalLabels } = {
      'Add New Admin': {
        autocompleteLabel: '',
        modalDesc: 'Type a name or email to Add a New Admin.',
        snackMsg: 'New Admin(s) has been created',
      },
      'Add New Member': {
        autocompleteLabel: '',
        modalDesc: 'Type a name or email to Add a New Member.',
        snackMsg: 'Group member(s) added successfully',
      },
      'Create New Group': {
        autocompleteHelperText: 'Type in the name of your members',
        autocompleteLabel: 'Group Members',
        modalDesc: 'Please fill the input below and add members to Create a New Group.',
        snackMsg: `${nameValue} has been created successfully`,
      },
    };

    return labelsData[label] || {};
  };

  // Sets the proper modal text for different actions
  const { autocompleteHelperText, autocompleteLabel, modalDesc, snackMsg } = getModalLabels(
    actionLabel,
    nameInputValue,
  );

  // Handler for updating the input
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setNameInputValue(value);
  };

  // Empties the input value, returns the modal's button to the disabled state for subsequent adds and closes it
  const handleClose = () => {
    setIsConfirmBtnDisabled(true);
    setNameInputValue('');
    setOpen(false);
  };

  // Handler for the create operation
  const handleCreate = async () => {
    let isSnackSuccessful: boolean;
    switch (actionLabel) {
      case 'Add New Admin':
        if (usersIdArr.length !== 0) {
          const res = await dispatch(postNewAdmin(usersIdArr.map(({ memberName }) => memberName)));
          isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
        }
        break;
      case 'Add New Member':
        if (usersIdArr.length !== 0 && groupId) {
          const res = await dispatch(addGroupMembers({ groupId, members: usersIdArr }));
          isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
        }
        break;
      case 'Create New Group':
        if (nameInputValue !== '') {
          const res = await dispatch(postNewGroup({ usersIdArr, name: nameInputValue }));
          isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
        }
        break;
      default:
        break;
    }
    handleClose();
    setSnackPack((prev) => [...prev, { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() }]);
  };

  // Handler to enable or disable the confirm creation button
  useEffect(() => {
    setIsConfirmBtnDisabled(!(nameInput ? nameInputValue !== '' && usersIdArr.length !== 0 : usersIdArr.length !== 0));
  }, [nameInput, nameInputValue, usersIdArr]);

  return (
    <AzulModal
      modalActions={
        <div className={styles.btnContainer} style={{ marginTop: nameInput ? '' : '3.5rem' }}>
          {/* ONLY renders the twin cancel button if cancelActionLabel prop was provided */}
          {cancelActionLabel && (
            <button className={`${styles.actionBtn} ${styles.cancelAction}`} type='button' onClick={handleClose}>
              {cancelActionLabel}
            </button>
          )}

          {/* Has logic for checking if users were added 
            Search autocomplete has values -> enable button and close with setOpen(false) 
            Search autocomplete is empty -> disable button and stay open */}
          <BlueAddButton
            className={`${styles.actionBtn} ${styles.doAction}`}
            disableElevation
            disabled={isConfirmBtnDisabled}
            variant='contained'
            onClick={handleCreate}
          >
            {actionLabel}
          </BlueAddButton>
        </div>
      }
      modalContent={
        <Fragment>
          {nameInput && (
            <AzulInput
              label={nameInput}
              margin='0 0 1.5rem 0'
              value={nameInputValue}
              width='100%'
              onChange={handleChange}
            />
          )}

          <SearchAutocomplete
            groupId={groupId}
            helperText={autocompleteHelperText}
            label={autocompleteLabel}
            setUsersIdArr={setUsersIdArr}
          />
        </Fragment>
      }
      open={open}
      title={actionLabel}
      titleHelperText={modalDesc}
      onClose={handleClose}
    >
      <BlueAddButton
        className={`${styles.actionBtn} ${styles.doAction}`}
        disableElevation
        variant='contained'
        onClick={() => setOpen(true)}
      >
        {actionLabel}
      </BlueAddButton>
    </AzulModal>
  );
};
