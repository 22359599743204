import { AudienceBackground } from 'type/background';
import { AudienceType } from '../utils/constants/audienceType';
import { BaseService } from './baseService';

class AudienceBackgroundService extends BaseService<AudienceBackground> {
  controllerName = 'audienceBackground';

  async createNewAudienceBackground(backgroundId: number, value: number | string) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const audienceType = typeof value === 'string' ? AudienceType.individual : AudienceType.group;
    const bodyData =
      audienceType === AudienceType.individual ? { backgroundId, value } : { backgroundId, groupId: value };
    const res = await this.api.post(`${this.controllerName}/${audienceType}`, bodyData);
    return res.data.data;
  }
}

export const audienceBackgroundService = new AudienceBackgroundService();
