import AzulLoadingState from 'components/simpleComponents/AzulLoadingState';
import { ColorPicker } from 'components/colorPicker/ColorPicker';
import { sha256 } from 'crypto-hash';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import * as htmlToImage from 'html-to-image';
import { FC, useState, useEffect, SyntheticEvent, useContext, useMemo, Fragment, MouseEvent } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AudienceType } from 'utils/constants/audienceType';
import { PublishingStatus } from 'utils/constants/publishingStatus';
import { Services } from 'utils/constants/services';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, ThemeProvider, SelectChangeEvent, Typography } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../assets/ArrowDownGray.svg';
import Canvas from '../../components/canvas/Canvas';
import AddImageButton from '../../components/createBackgroundModal/CreateBackgroundModal';
import FormConfirmationModal from '../../components/formConfirmationModal/FormConfirmationModal';
import DragAndResizeImages, { ImgProps } from '../../components/resizableAndDraggable/DragAndResizeImages';
import AzulDatePicker from '../../components/simpleComponents/AzulDatePicker';
import { azulDesign } from '../../components/simpleComponents/AzulDesignTheme';
import AzulInput from '../../components/simpleComponents/AzulInput';
import AzulSelect from '../../components/simpleComponents/AzulSelect';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { fileService } from '../../services/fileService';
import {
  clearSelectedBackground,
  postNewBackground,
  readBackgroundById,
  updateBackground,
} from '../../store/features/backgroundSlice';
import snackContext from '../../store/features/contextSnackbar';
import { readAllGroups } from '../../store/features/groupSlice';
import { readAllUsers } from '../../store/features/userSlice';
import { backgroundWhite } from '../../styles/partials/variables';
import { Group } from '../../type/group';
import { SynkronUser } from '../../type/user';
import { DURATIONS } from '../../utils/constants/durationsAndTimezones';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from './BackgroundForm.module.scss';

type BackgroundFormProps = {
  audienceType: AudienceType.Group | AudienceType.Individual; // Handles if the service is for Group or Individual Announcements
  service?: 'create' | 'edit'; // The operation to use with the form info
};

const BackgroundForm: FC<BackgroundFormProps> = ({ audienceType, service = 'create' }: BackgroundFormProps) => {
  const isBackgroundByGroup = audienceType === AudienceType.Group;
  const isServiceCreate = service === Services.Create;
  //  ===============================  H O O K S  ===============================
  const dispatch = useAppDispatch();
  const { setSnackPack } = useContext(snackContext);
  const navigate = useNavigate();
  const location = useLocation(); // for receiving values from other views sent through useNavigate
  const params = useParams(); // grabs the path's params we're interested on -> The id of the group to edit
  const backgroundSelected = useAppSelector((state) => state.background.backgroundSelected);
  const backgroundId = Number(params.id);
  dayjs.extend(utc);
  dayjs.extend(isToday);
  const [colorValue, setColorValue] = useState(backgroundWhite);

  //  ===============================  C A N V A S   &   I M A G E S  ===============================
  let fileReader: FileReader; // to read the bg img file that will be hashed
  const canvas = document.getElementById('canvas');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [canvasColorChanged, setCanvasColorChanged] = useState(false);
  const [showTextTools, setShowTextTools] = useState(false);
  const [imagesArr, setImagesArr] = useState<ImgProps[]>([]);
  const [imagesArrCount, setImagesArrCount] = useState<number>(0); // counter to track the number of images added to the canvas, for validation of save and publish btns
  const [customBackgroundImgUrl, setCustomBackgroundImgUrl] = useState<string>();
  const [customBackgroundImgId, setCustomBackgroundImgId] = useState<number | null>(null);
  const open = Boolean(anchorEl);
  const idColorPicker = open ? 'background-color-picker-popover' : undefined;
  const importedBackgroundImgUrl = location.state?.url;
  const editDisabled = location.state?.noEdit; // canvas edit chips are disabled ONLY when coming from the import an image option, enabled for use a template
  // Saves information about the url of the imported image on create mode and passed to this view through a use navigate
  useMemo(() => {
    if (importedBackgroundImgUrl && importedBackgroundImgUrl !== customBackgroundImgUrl) {
      setCustomBackgroundImgUrl(importedBackgroundImgUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedBackgroundImgUrl]);

  //  ===============================  F O R M   V A L U E S  ===============================
  const MAX_NAME_LENGTH = 100;
  const [backgroundName, setBackgroundName] = useState<string>('');
  const [selectedDuration, setSelectedDuration] = useState<string>('');
  const [date, setDate] = useState<Dayjs | null>(null);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [isDateInvalid, setIsDateInvalid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(true);
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState<boolean>(true);
  const [isSaveChangesDisabled, setIsSaveChangesDisabled] = useState<boolean>(true);
  const [isTitleChipDisabled, setIsTitleChipDisabled] = useState<boolean>(false);
  const [isSubtitleChipDisabled, setIsSubtitleChipDisabled] = useState<boolean>(false);
  const [hideTitleBox, setHideTitleBox] = useState(true);
  const [hideSubtitleBox, setHideSubtitleBox] = useState(true);
  const [selectedAudienceArr, setSelectedAudienceArr] = useState<string[]>([]);
  const [previousAudience, setPreviousAudience] = useState<string[]>([]);
  const [isEditDurationValid, setIsEditDurationValid] = useState<boolean>(true);
  // Saves information about the audience of the current background before editing
  useMemo(() => {
    const oldAudience: string[] = [];
    if (backgroundSelected) {
      backgroundSelected?.AudienceBackground.forEach(({ fullName, group }) => {
        if (group) {
          if (!oldAudience.includes(group.name)) {
            oldAudience.push(group.name);
          }
        } else if (!oldAudience.includes(fullName)) {
          oldAudience.push(fullName);
        }
      });
      setPreviousAudience(oldAudience);
    }
  }, [backgroundSelected]);

  useEffect(() => {
    if (canvas) canvas.style.backgroundColor = colorValue;
    if (colorValue === backgroundWhite) setCanvasColorChanged(false);
    else setCanvasColorChanged(true);
  }, [canvas, colorValue]);

  const avialableAudience: Array<Group | SynkronUser> = useAppSelector((state) =>
    isBackgroundByGroup ? state.group.groups : state.user.users,
  );
  const [selectedAudienceIdsArr, setSelectedAudienceIdsArr] = useState<number[]>([]);
  const today = dayjs().utc();
  const todayDate = today.format('YYYY-MM-DD');
  const maxTodayPublishingTime = dayjs.utc(`${todayDate}T23:30:00+00:00`);

  const isSaveDraftDisabledValidation =
    selectedAudienceArr.length > 0 &&
    backgroundName.length &&
    !isDateInvalid &&
    date &&
    (backgroundName.length ||
      selectedDuration !== '' ||
      date !== null ||
      !hideSubtitleBox ||
      !hideTitleBox ||
      canvasColorChanged ||
      Number(canvas?.childElementCount) > 3 ||
      imagesArr.length > 0);

  const isSaveChangesDisabledValidation =
    selectedAudienceArr &&
    (backgroundSelected?.name !== backgroundName ||
      backgroundSelected?.duration !== Number(selectedDuration) ||
      selectedAudienceArr.toString() !== previousAudience.toString() ||
      !hideSubtitleBox ||
      !hideTitleBox ||
      canvasColorChanged ||
      imagesArrCount > 0 ||
      (backgroundSelected?.date.toString() !== date?.toDate().toISOString().split('T')[0] && !isDateInvalid));

  const isPublishDisabledValidation =
    (isServiceCreate &&
      hideSubtitleBox &&
      hideTitleBox &&
      !canvasColorChanged &&
      imagesArrCount === 0 &&
      !importedBackgroundImgUrl) ||
    backgroundName.length < 1 ||
    isNameInvalid ||
    Number(selectedDuration) === 0 ||
    selectedAudienceArr.length === 0 ||
    !date ||
    isDateInvalid ||
    backgroundSelected?.status === PublishingStatus.Published || // no need to publish something that has already been published
    backgroundSelected?.status === PublishingStatus.Scheduled; // no need to publish something that has already been published as scheduled

  const isBackgroundPublished = backgroundSelected?.status === PublishingStatus.Published;

  // =========================  C A N V A S   C U S T O M I Z A T I O N  =========================

  // Removes the title or subtitle from the canvas when clicking their respective 'x' button
  const handleOnDeleteText = (hide: boolean, isTitle?: boolean) => {
    if (isTitle) {
      setHideTitleBox(hide);
      setIsTitleChipDisabled(!hide);
    } else {
      setHideSubtitleBox(hide);
      setIsSubtitleChipDisabled(!hide);
    }
  };
  // Adds a (sub)title or image when clicking the add (sub)title or image buttons
  const handleOnAddText = (isTitle?: boolean) => {
    if (canvas) {
      if (isTitle) {
        setHideTitleBox(false);
        setIsTitleChipDisabled(true);
        const titleBox = document.getElementById('movingTitle');
        titleBox?.classList.remove(`${styles.hidden}`);
      } else {
        setHideSubtitleBox(false);
        setIsSubtitleChipDisabled(true);
        const subtitleBox = document.getElementById('movingSubtitle');
        subtitleBox?.classList.remove(`${styles.hidden}`);
      }
    }
  };

  const handleClickChangeColor = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBackgroundColorPicker = () => {
    setAnchorEl(null);
  };

  // ============================  C A N V A S  I M A G E S  ============================

  const loadImageToCanvas = (imageUrl: string) => {
    const imageLoaded: ImgProps = {
      x: 50,
      y: 50,
      width: 300,
      height: 200,
      image: imageUrl,
      id: uuidv4(), // generates unique id for each img
    };
    setImagesArr((prevArr) => [...prevArr, imageLoaded]);
  };

  const convertCanvasToImageUrl = async () => {
    if (!canvas) return '';
    const img = await htmlToImage
      .toJpeg(canvas, { quality: 1, canvasHeight: (2056 / 792) * 424, canvasWidth: 2056 }) // converts canvas into a 2k background img
      .then((dataUrl: string) => dataUrl)
      .catch((err: Error) => {
        console.error(err);
        return '';
      });
    return img;
  };

  const createFileFromUrl = async (url: string, name: string, defaultType = 'image/jpeg') => {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], `${name}.jpeg`, {
      type: defaultType,
    });
  };

  // handles file reading, posting and updating
  const handleFileCRUD = async (backgroundImgId: number, isDraft: boolean) => {
    const content = fileReader.result;
    if (content) {
      const hash = await sha256(content);

      let isSnackSuccessful = false;
      let snackMsg: string;

      // TODO: handle possible error in post, on error set issubmitting to false
      if (isServiceCreate && date && backgroundImgId && hash) {
        const res = await dispatch(
          postNewBackground({
            name: backgroundName,
            image: backgroundImgId,
            status: isDraft ? PublishingStatus.Draft : PublishingStatus.Scheduled,
            audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
            duration: Number(selectedDuration),
            date,
            checksum: hash,
          }),
        );
        isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
        snackMsg = `The background has been ${isDraft ? 'saved as draft' : 'created'}`;
        // remove the (&& date) when they are set to optional on backend
      } else if (!isServiceCreate && date && backgroundSelected && selectedAudienceIdsArr && backgroundImgId && hash) {
        const res = await dispatch(
          updateBackground({
            id: backgroundId,
            name: backgroundName,
            image: backgroundImgId, // the current (maybe edited) canvas image
            status: isDraft ? backgroundSelected.status : PublishingStatus.Scheduled,
            audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
            currentAudience: backgroundSelected.AudienceBackground,
            duration: Number(selectedDuration),
            date,
            checksum: hash,
          }),
        );
        isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
        snackMsg = 'The background has been edited';
      }
      if (isSnackSuccessful) navigate(`/background${isBackgroundByGroup ? '' : '#individual'}`);
      setSnackPack((prev) => [
        ...prev,
        { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() },
      ]);
    }
  };

  const handleFileChosen = async (file: File, bgImgId: number, isDraft: boolean) => {
    fileReader = new FileReader();
    fileReader.onloadend = () => handleFileCRUD(bgImgId, isDraft);
    fileReader.readAsArrayBuffer(file);
  };

  const loadImageToS3 = async (isDraft: boolean, importedImgUrl?: string) => {
    const bgImgUrl = importedImgUrl || (await convertCanvasToImageUrl());
    const imgFileName = uuidv4();
    const customBackgroundImgFile = await createFileFromUrl(bgImgUrl, imgFileName);
    if (!customBackgroundImgFile) return null;

    const formData = new FormData();
    formData.append('file', customBackgroundImgFile);
    try {
      // Set all buttons to disabled to avoid user interaction while the request is loading
      setIsSubmitting(true);
      const newFile = await fileService.uploadFile(customBackgroundImgFile, customBackgroundImgFile.name);
      await handleFileChosen(customBackgroundImgFile, Number(newFile.id), isDraft);
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const audienceOptions = avialableAudience.map((audience) => {
    const value = isBackgroundByGroup ? (audience as Group).name : (audience as SynkronUser).fullName;
    return {
      label: value,
      value,
    };
  });

  // =========================  F O R M   H A N D L E R S  ==========================

  const handleNameChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLTextAreaElement;
    setBackgroundName(value);
    setIsNameInvalid(value.length > MAX_NAME_LENGTH);
  };

  const handleAudienceChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedAudienceArr(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(', ') : value,
    );
  };

  const handleDurationChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target as HTMLSelectElement;
    if (isBackgroundPublished) {
      setIsEditDurationValid(Number(value) >= backgroundSelected?.duration);
    }
    setSelectedDuration(value);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setIsDateInvalid(!newDate?.isValid() || (newDate.isToday() && today > maxTodayPublishingTime));
      setDate(newDate);
    }
  };

  const fetchBackgroundFromS3 = async (fileId: number) => {
    const { data: s3File } = await fileService.getById(fileId);
    setCustomBackgroundImgUrl(s3File.url);
  };

  // Handler for publishing or saving on the create and edit services
  const handlePublish = async (isDraft: boolean) => {
    await loadImageToS3(isDraft);
  };

  // ============================  U S E   E F F E C T  ============================
  // TO DO: Redux upgrade to reduce these many lines of code

  // Fetch the correct type of audience
  useEffect(() => {
    if (isBackgroundByGroup) {
      dispatch(readAllGroups());
    } else dispatch(readAllUsers());
  }, [dispatch, isBackgroundByGroup]);

  // When editing, fetch the current info of the selected Background
  useEffect(() => {
    if (!isServiceCreate) {
      const editBackgroundInfo = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { payload }: any = await dispatch(readBackgroundById(backgroundId)); // couldnt fix the any
        const bgInfo = payload;
        setCustomBackgroundImgId(bgInfo.image);
      };
      editBackgroundInfo();
    } else {
      dispatch(clearSelectedBackground());
    }
  }, [backgroundId, dispatch, isServiceCreate]);

  // When editing, set the current info in the inputs as the current values
  useEffect(() => {
    if (!isServiceCreate && backgroundSelected) {
      setBackgroundName(backgroundSelected.name);
      const bgAudience: string[] = [];
      backgroundSelected.AudienceBackground.forEach(({ fullName, group }) => {
        if (group) {
          if (!bgAudience.includes(group.name)) {
            bgAudience.push(group.name);
          }
        } else if (!bgAudience.includes(fullName)) {
          bgAudience.push(fullName);
        }
      });
      setSelectedAudienceArr(bgAudience);
      setSelectedDuration(`${backgroundSelected.duration}`);
      setDate(dayjs(backgroundSelected.date));
      setCustomBackgroundImgId(backgroundSelected.image);
      if (customBackgroundImgId) fetchBackgroundFromS3(customBackgroundImgId);
    }
  }, [backgroundSelected, isServiceCreate, customBackgroundImgId]);

  // Updates the selectedAudienceIdsArr values used for services when selectedAudienceArr changes
  useEffect(() => {
    if (isBackgroundByGroup) {
      const audienceIds: number[] = [];
      selectedAudienceArr.forEach((selectedAudienceName) => {
        (avialableAudience as Group[]).forEach(({ id, name: audienceName }) => {
          if (selectedAudienceName === audienceName) audienceIds.push(id);
        });
      });
      setSelectedAudienceIdsArr(audienceIds);
    }
  }, [avialableAudience, selectedAudienceArr, isBackgroundByGroup]);

  // Validates when the publish or save changes buttons are disabled
  useEffect(() => {
    setIsTitleChipDisabled(!hideTitleBox);
    setIsSubtitleChipDisabled(!hideSubtitleBox);
    setIsPublishDisabled(isPublishDisabledValidation);
    setIsSaveDraftDisabled(!isSaveDraftDisabledValidation);
    setIsSaveChangesDisabled(!isSaveChangesDisabledValidation);
  }, [
    hideSubtitleBox,
    hideTitleBox,
    isSaveDraftDisabledValidation,
    isPublishDisabledValidation,
    isSaveChangesDisabledValidation,
  ]);

  return (
    <ThemeProvider theme={azulDesign}>
      <Fragment>
        {/* If we're creating a new background, show the bg form, otherwise if we're editing or submitting, show the spinner while the bg image loads */}
        {(isServiceCreate && !isSubmitting) || (customBackgroundImgUrl && customBackgroundImgId && !isSubmitting) ? (
          <Box sx={{ display: 'flex', width: '75.25%', marginTop: '6rem' }}>
            {/* =========================  L E F T   S I D E B A R  ======================== */}
            <aside className={styles.left}>
              {/* TO DO: eventually add just ONE modal and leave all buttons that can trigger it on this view */}
              <FormConfirmationModal
                isFormByGroup={isBackgroundByGroup}
                isServiceCreate={isServiceCreate}
                label='Background'
                leaveForm
                primaryActionLabel='Publish'
                secondaryActionLabel={isServiceCreate ? 'Save as draft' : 'Save changes'}
                secondaryDisabled={isServiceCreate ? isSaveDraftDisabled : isSaveChangesDisabled}
                onPublish={(isDraft) => handlePublish(isDraft)}
              />
            </aside>
            <main className={styles.mainContainer}>
              {/* ==========================  H E A D E R  ============================== */}
              <header>
                <Typography variant='viewHeader'>
                  {' '}
                  {isServiceCreate ? 'Create a New' : 'Edit'} {audienceType} Background
                </Typography>
                <Typography sx={{ marginTop: '8px' }} variant='paragraph14'>
                  <Typography sx={{ fontWeight: 600, display: 'inline-block' }} variant='paragraph14'>
                    Current background:
                  </Typography>{' '}
                  {backgroundName || '(No Background Name)'}
                </Typography>
                {/* ===========================  C H I P S  ============================== */}
                <Box
                  className={styles.chipsBox}
                  sx={{ display: editDisabled ? 'none' : 'flex' }} // Chips are hidden for "import and image" mode
                >
                  {/* Group of choice chips with a particular theme, they allow customizing the canvas  */}
                  <ThemeProvider theme={azulDesign}>
                    <Button aria-describedby={idColorPicker} variant='azulChip' onClick={handleClickChangeColor}>
                      change color
                    </Button>
                    <Button disabled={isTitleChipDisabled} variant='azulChip' onClick={() => handleOnAddText(true)}>
                      add title
                    </Button>
                    <Button disabled={isSubtitleChipDisabled} variant='azulChip' onClick={() => handleOnAddText()}>
                      add subtitle
                    </Button>
                    <AddImageButton // renamed the CreateBackgroundModal component on import
                      addCanvasImage
                      // Can additionally take in a file
                      addImage={(imgFileUrl) => {
                        loadImageToCanvas(imgFileUrl);
                        // TODO: create another fn or refactor bc this one has now changed
                        // loadImageToS3(imgFileUrl); // TODO: uncomment this to load each indiv img added to the canvas to S3 (for fetching them later on edit)
                      }}
                      audienceType={isBackgroundByGroup ? 'groups' : 'individuals'}
                    />
                  </ThemeProvider>
                </Box>
              </header>
              {/* ===========================  C A N V A S  ============================== */}
              <ColorPicker
                anchorEl={anchorEl}
                color={colorValue}
                open={open}
                pickerId={idColorPicker}
                onChange={setColorValue}
                onClose={handleCloseBackgroundColorPicker}
              />
              <Box className={styles.canvasBox}>
                <Canvas
                  customBackgroundImgUrl={customBackgroundImgUrl}
                  hideSubtitleBox={hideSubtitleBox}
                  hideTitleBox={hideTitleBox}
                  setShowTextTools={setShowTextTools}
                  showTextTools={showTextTools}
                  onTextDelete={handleOnDeleteText}
                >
                  <DragAndResizeImages
                    imageCount={(count) => setImagesArrCount(count)}
                    imagesToRender={imagesArr}
                    resetImagesArray={() => setImagesArr([])}
                  />
                </Canvas>
              </Box>
              {/* =============================  F O R M  ============================== */}
              <form>
                <Box className={styles.formBox}>
                  {/* ============================== NAME INPUT ============================== */}
                  <AzulInput
                    error={isNameInvalid}
                    helperText={`${backgroundName.length}/${MAX_NAME_LENGTH}`}
                    helperTextAlign='end'
                    id='background-name'
                    label='Background Name'
                    value={backgroundName}
                    width='47.5%'
                    onChange={handleNameChange}
                  />
                  {/* ============================== AUDIENCE SELECT ============================== */}
                  <AzulSelect
                    IconComponent={ArrowDownIcon}
                    id='audience-select'
                    label='Choose audience'
                    labelId='audience-select-label'
                    multiple
                    options={audienceOptions}
                    searchBar
                    value={selectedAudienceArr}
                    width='47.5%'
                    onChange={handleAudienceChange}
                  />
                </Box>

                <Box className={styles.formBox}>
                  {/* ============================== DURATION SELECT ============================== */}
                  <AzulSelect
                    IconComponent={ArrowDownIcon}
                    error={!isEditDurationValid}
                    helperText={!isEditDurationValid ? 'Currently only to extend the duration.' : ''}
                    id='duration-select'
                    label='Publication Duration'
                    labelId='duration-select-label'
                    options={DURATIONS}
                    value={selectedDuration}
                    width='47.5%'
                    onChange={handleDurationChange}
                  />

                  {/* ============================== DATE PICKER ============================== */}
                  <AzulDatePicker
                    disablePast
                    disabled={isBackgroundPublished}
                    helperText={isDateInvalid ? 'Please choose a valid date.' : 'MM/DD/YYYY'}
                    label='Publication Date'
                    value={date}
                    width='47.5%'
                    onChange={handleDateChange}
                  />
                </Box>
              </form>

              {/* ==========================  F O O T E R   B U T T O N S  =========================== */}
              <footer>
                <FormConfirmationModal
                  isFormByGroup={isBackgroundByGroup}
                  isServiceCreate={isServiceCreate}
                  label='Background'
                  primaryActionLabel='Publish'
                  primaryDisabled={isPublishDisabled}
                  secondaryActionLabel={isServiceCreate ? 'Save as draft' : 'Save changes'}
                  secondaryDisabled={isServiceCreate ? isSaveDraftDisabled : isSaveChangesDisabled}
                  onPublish={(isDraft) => handlePublish(isDraft)}
                />
              </footer>
            </main>
          </Box>
        ) : (
          <AzulLoadingState
            subtitle='We are loading your background, this will take a few seconds.'
            title='Please wait. Loading your Background'
          />
        )}
        <SimpleSnackbar />
      </Fragment>
    </ThemeProvider>
  );
};

export default BackgroundForm;
