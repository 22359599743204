import { FC, MouseEvent } from 'react';
import { TextBoxType } from 'type/canvas';
import { TextBox } from 'utils/constants/canvas';
import { Box, Typography } from '@mui/material';
import deleteImageButton from '../../assets/deleteImgBtn.png';
import useDragger from '../../utils/hooks/useDragger';
import styles from './ResizableAndDraggable.module.scss';

type DraggableTextBoxProps = {
  activeColorEditing: boolean;
  textColor: string;
  textId: TextBoxType; // The id of the component that will be dragged, help determine styles too
  onClickTextBox: () => void;
  onDelete: () => void; // handles the input query outside the component in the Bg form. Helps determine states of other components
};

const DraggableTextBox: FC<DraggableTextBoxProps> = ({
  activeColorEditing,
  textColor,
  textId,
  onClickTextBox,
  onDelete,
}: DraggableTextBoxProps) => {
  const isTitle = textId === TextBox.Title;
  const inputVariant = isTitle ? 'canvasTitle' : 'canvasSubtitle';
  const boxPadding = 16;
  // Custom hook for dragging components
  useDragger(textId, boxPadding);

  // For "deleting" the textbox by hiding it from the display
  function hideTextBox(event: MouseEvent<HTMLElement>) {
    event.stopPropagation();
    event.currentTarget.parentElement?.classList.add(`${styles.hidden}`);
    onDelete();
  }

  const handleClickTextBox = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClickTextBox();
  };

  return (
    <Box className={styles.outerBox} id={textId} onClick={handleClickTextBox}>
      {/* The text input must stay as span because of its content editable property */}
      <Typography
        className={`${styles.inputBox} ${activeColorEditing && styles.activeColorEditingInputBox}`}
        color={textColor}
        component='span'
        contentEditable
        variant={inputVariant}
      >
        Enter your {textId}
      </Typography>
      <button className={styles.deleteInput} onClick={(e) => hideTextBox(e)}>
        <img alt='Delete img icon' src={deleteImageButton} />
      </button>
    </Box>
  );
};

export default DraggableTextBox;
