import { FC, DragEvent, SyntheticEvent, useRef, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as BackIcon } from '../../assets/BackArrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';
import { ReactComponent as FileIcon } from '../../assets/File.svg';
import { ReactComponent as ImportImageIcon } from '../../assets/ImportImage.svg';
import { ReactComponent as UseTemplateIcon } from '../../assets/UseTemplate.svg';
import { primaryBlue, disabledGray1 } from '../../styles/partials/variables';
import buttonStyles from '../blueAddBtn/BlueAddBtn.module.scss';
import { BlueAddButton } from '../blueAddBtn/StyledBtn';
import Modal from '../simpleComponents/Modal';
import styles from './CreateBackgroundModal.module.scss';

const ModalBtnCreate = styled(Button)(() => ({
  border: `1px solid ${disabledGray1}`,
  borderRadius: '8px',
  boxShadow: '0px 5px 10px rgba(29, 63, 156, 0.1)',
  height: '9rem',
  margin: '0 2rem 0 2rem',
  width: '9rem',
}));

const ModalBackBtn = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  textTransform: 'none',
  color: primaryBlue,
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  '.MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

type CreateBackgroundModalProps = {
  audienceType: string; // According to the Tab value
  addCanvasImage?: boolean; // The utility of the modal, creating a bg from scratch or just adding an img to the canvas
  buttonLabel?: string; // Label for the button that opens the modal
  disabled?: boolean;
  addImage?: (url: string, file: File) => void;
};

const CreateBackgroundModal: FC<CreateBackgroundModalProps> = ({
  audienceType,
  addCanvasImage = false,
  buttonLabel,
  disabled,
  addImage,
}: CreateBackgroundModalProps) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<boolean>(false);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  // Handler for the Create New button onClick event
  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  // Handler for drag events in drag and drop zone
  const handleDrag = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // Handler for drop event in drag and drop zone
  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event?.dataTransfer?.files && event.dataTransfer.files[0]) {
      setSelectedFile(event.dataTransfer.files[0]);
    }
  };

  // Handler for file browser opening click
  const handleFileBrowserClick = () => {
    // `current` points to the mounted file input element
    inputFile?.current?.click();
  };

  // Handler for file browser on change event
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.stopPropagation();
    event?.preventDefault();
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const file = target.files[0];
      setSelectedFile(file);
    }
  };

  // Adds the image file to the canvas as a draggable and resizable component
  const handleAddImageToCanvas = () => {
    setIsCreateModalOpen(false);
    if (selectedFile && addImage) addImage(URL.createObjectURL(selectedFile), selectedFile);
    setSelectedFile(null);
  };

  const handleAddImageAsBackground = () => {
    setIsCreateModalOpen(false);
    if (selectedFile) {
      const bgimgurl = URL.createObjectURL(selectedFile);
      navigate(`/background/create/${audienceType}`, { state: { url: bgimgurl, noEdit: true } });
    }
    setSelectedFile(null);
  };

  const ModalActionsStepOne = (
    <Box className={`${styles.createOptionsContainer}`}>
      <Box className={`${styles.createOptionContainer}`}>
        <ModalBtnCreate disableFocusRipple onClick={() => navigate(`/background/create/${audienceType}`)}>
          <UseTemplateIcon />
        </ModalBtnCreate>
        <Typography sx={{ margin: '1rem 0 5.5rem 0' }}>Use a Template</Typography>
      </Box>

      <Box className={`${styles.createOptionContainer}`}>
        <ModalBtnCreate
          disableFocusRipple
          onClick={() => {
            setModalStep(true);
          }}
        >
          <ImportImageIcon />
        </ModalBtnCreate>
        <Typography sx={{ margin: '1rem 0 5.5rem 0' }}>Import an Image</Typography>
      </Box>
    </Box>
  );

  const ModalActionsStepTwo = (
    <Box className={`${styles.createOptionsContainer} ${styles.modalActions2}`}>
      {!addCanvasImage && (
        <ModalBackBtn
          className={styles.backModalBtn}
          startIcon={<BackIcon fill={primaryBlue} />}
          onClick={() => {
            setModalStep(false);
          }}
        >
          Back
        </ModalBackBtn>
      )}
      <BlueAddButton
        disableElevation
        disabled={!selectedFile}
        variant='contained'
        onClick={addCanvasImage ? handleAddImageToCanvas : handleAddImageAsBackground}
      >
        Continue
      </BlueAddButton>
    </Box>
  );

  const ModalContent2 = (
    <Fragment>
      <Box
        className={`${styles.dropFiles}`}
        sx={{ borderColor: dragActive ? primaryBlue : 'black' }}
        onDragEnter={handleDrag}
      >
        {/* This div allows to drag over the box without other elements interrupting */}
        {dragActive && (
          <div
            className={`${styles.dragFileElement}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
        <Typography>
          Drag and drop files here
          <br />
          or
        </Typography>

        <button
          className={`${buttonStyles.actionBtn} ${buttonStyles.cancelAction}`}
          disabled={!!selectedFile}
          onClick={handleFileBrowserClick}
        >
          Browse Files
        </button>
        {/* File Browser, only takes image files */}
        <input
          accept='image/*'
          id='file'
          ref={inputFile}
          style={{ display: 'none' }}
          type='file'
          onChange={onChangeFile}
        />
      </Box>

      <div style={{ visibility: selectedFile ? 'visible' : 'hidden' }}>
        <Box className={`${styles.selectedFile}`}>
          <Box className={styles.selectedFileLabel}>
            <FileIcon />
            <Typography marginLeft='1rem'>{selectedFile?.name}</Typography>
          </Box>

          <IconButton
            aria-label='Cancel selection'
            onClick={() => {
              setSelectedFile(null);
              if (inputFile.current) inputFile.current.value = '';
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </div>
    </Fragment>
  );

  return (
    <Modal
      modalActions={modalStep || addCanvasImage ? ModalActionsStepTwo : ModalActionsStepOne}
      modalContent={modalStep || addCanvasImage ? ModalContent2 : null}
      open={isCreateModalOpen}
      padding='3.5rem 5.5rem 3.5rem 5.5rem'
      title='Create New Background'
      titleHelperText={
        addCanvasImage || modalStep ? 'Drag and drop or browse one image.' : 'How would you like to start?'
      }
      onClose={() => {
        setIsCreateModalOpen(false);
        setModalStep(false);
        setSelectedFile(null);
      }}
    >
      {addCanvasImage ? (
        <Button disabled={disabled} variant='azulChip' onClick={handleOpenCreateModal}>
          {' '}
          Add image{' '}
        </Button>
      ) : (
        <BlueAddButton
          className={`${styles.actionBtn} ${styles.doAction}`}
          disableElevation
          disabled={disabled}
          variant='contained'
          onClick={handleOpenCreateModal}
        >
          {buttonLabel || 'Create New'}
        </BlueAddButton>
      )}
    </Modal>
  );
};

export default CreateBackgroundModal;
