import axios, { AxiosInstance } from 'axios';
import { RootState, store } from '../store/store';
import { Credentials } from '../type/credentials';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_TIMEOUT: number = 600000 * 1.01;
export const BASE_HEADERS = {
  'Content-Type': 'application/json',
};
const selectSessionToken = (state: RootState) => state?.session?.credentials?.token;

export interface ResponseData<T> {
  data: T;
  message?: string;
}

class AuthService {
  controllerName = 'auth';

  baseUrl: string | undefined;

  apiTimeOut: number = API_TIMEOUT;

  apiInstance: AxiosInstance | undefined;

  get api() {
    if (this.apiInstance == null || this.baseUrl == null) {
      this.baseUrl = API_BASE_URL;
      if (this.baseUrl == null) {
        console.warn('Your REACT_APP_API_BASE_URL is not defined, make sure it is correctly set in .env');
      }
      this.apiInstance = axios.create({
        timeout: this.apiTimeOut,
        headers: BASE_HEADERS,
        baseURL: `${this.baseUrl}`,
      });
    }
    return this.apiInstance;
  }

  getDefaultHeaders() {
    const token =
      process.env.REACT_APP_DEVELOPMENT_MODE === 'true' && process.env.REACT_APP_USE_DEV_TOKEN === 'true'
        ? process.env.REACT_APP_DEVELOPMENT_TOKEN
        : selectSessionToken(store.getState());

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let defaultHeaders: any = {
      ...BASE_HEADERS,
    };
    if (token) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      };
    }
    return defaultHeaders;
  }

  async refresh(currentCredentials: Credentials): Promise<ResponseData<Credentials>> {
    const { data } = await this.api.post<ResponseData<Credentials>>(`/${this.controllerName}/refresh`, undefined, {
      headers: {
        ...BASE_HEADERS,
        Authorization: `Bearer ${currentCredentials.refresh_token.token}`,
      },
    });
    return data;
  }

  async logout(): Promise<ResponseData<Credentials>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(`/${this.controllerName}/logout`);

    return data;
  }

  async exchange(token: string): Promise<ResponseData<Credentials>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(`/${this.controllerName}/exchange`, undefined, {
      headers: {
        ...BASE_HEADERS,
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  }
}

export const authService = new AuthService();
