import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MenuState {
  selected: boolean;
  selectedItem?: string;
}

const initialState: MenuState = {
  selected: false,
  selectedItem: 'Dashboard',
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedItem: (state, action: PayloadAction<string>) => {
      state.selectedItem = action.payload;
      state.selected = true;
    },
  },
});

export const { setSelectedItem } = menuSlice.actions;

export default menuSlice.reducer;
