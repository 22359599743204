export enum HttpStatusCode {
  OK = 200,
  Created = 201,
  PermanentRedirect = 301,
  Unauthorized = 401,
  BadRequest = 400,
  NotFound = 404,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}
