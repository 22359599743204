import { User, SynkronUser } from '../type/user';
import { ServiceOptions, prepareParams, BaseService, ListResponseData, ResponseData } from './baseService';

class UserService extends BaseService<User> {
  controllerName = 'user';

  async fetchAvailableUsersToAdd(id: number, options?: ServiceOptions) {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ListResponseData<SynkronUser>>(`synkron/group/${id}`, {
      params,
    });
    return data;
  }

  async fetchRegularUsers(options?: ServiceOptions) {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ListResponseData<SynkronUser>>(`synkron/admin/add`, { params });
    return data;
  }

  async fetchAllSynkronUsers(options?: ServiceOptions) {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ListResponseData<SynkronUser>>(`synkron`, {
      params,
    });
    return data;
  }

  async createNewAdmin(bodyData: { value: string }) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post<ResponseData<User>>(
      `${this.controllerName}/adduser`,
      JSON.stringify(bodyData),
    );
    return data;
  }

  async deleteAdminById(id: number) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.delete(`${this.controllerName}/removeUser/${id}`);
    return data;
  }

  async fetchAllAdmins(options?: ServiceOptions) {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ListResponseData<User>>(`${this.controllerName}/getall`, { params });
    return data;
  }
}

export const userService = new UserService();
