import { ServiceOptions } from 'services/baseService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userService } from '../../services/usersService';
import { SynkronUser, UserState } from '../../type/user';

const initialState: UserState = {
  loading: false,
  users: [],
};

const options: ServiceOptions = {
  limit: 1000,
  offset: 0,
};

export const readAllUsers = createAsyncThunk<SynkronUser[]>(
  'user/readAllUsers',
  async (_: void, { rejectWithValue }) => {
    try {
      const { data } = await userService.fetchAllSynkronUsers(options);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readRegularUsers = createAsyncThunk<SynkronUser[]>(
  'user/readRegularUsers',
  async (_: void, { rejectWithValue }) => {
    try {
      const { data } = await userService.fetchRegularUsers(options);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readAvailableUsersToAdd = createAsyncThunk<SynkronUser[], number>(
  'user/readAvailableUsersToAdd',
  async (groupId: number, { rejectWithValue }) => {
    try {
      const { data } = await userService.fetchAvailableUsersToAdd(groupId, options);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    // ========================== READ ALL SYNKRON USERS ==========================
    // When our request is pending:
    builder.addCase(readAllUsers.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAllUsers.fulfilled, (state, action) => {
      state.users = action.payload.sort((firstItem, secondItem) => {
        if (firstItem.fullName < secondItem.fullName) {
          return -1;
        }
        if (firstItem.fullName > secondItem.fullName) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAllUsers.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
    // ========================== READ ALL REGULAR USERS ==========================
    // When our request is pending:
    builder.addCase(readRegularUsers.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readRegularUsers.fulfilled, (state, action) => {
      state.users = action.payload.sort((firstItem, secondItem) => {
        if (firstItem.fullName < secondItem.fullName) {
          return -1;
        }
        if (firstItem.fullName > secondItem.fullName) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readRegularUsers.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
    // ========================== READ AVAILABLE USERS TO ADD ==========================
    // When our request is pending:
    builder.addCase(readAvailableUsersToAdd.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAvailableUsersToAdd.fulfilled, (state, action) => {
      state.users = action.payload.sort((firstItem, secondItem) => {
        if (firstItem.fullName < secondItem.fullName) {
          return -1;
        }
        if (firstItem.fullName > secondItem.fullName) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAvailableUsersToAdd.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
  },
});

export default userSlice.reducer;
