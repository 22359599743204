import AzulContainer from 'components/simpleComponents/AzulContainer';
import { FC } from 'react';
import UnderWork from '../../components/underWork/UnderWork';

const AreaInfoRelated: FC = () => (
  <AzulContainer>
    <UnderWork pageName='Area Info Related Page' />
  </AzulContainer>
);

export default AreaInfoRelated;
