import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Squares0 } from '../../../assets/spinner/Squares0.svg';
import { ReactComponent as Squares1 } from '../../../assets/spinner/Squares1.svg';
import { ReactComponent as Squares2 } from '../../../assets/spinner/Squares2.svg';
import { ReactComponent as Squares3 } from '../../../assets/spinner/Squares3.svg';
import { ReactComponent as Squares4 } from '../../../assets/spinner/Squares4.svg';
import { ReactComponent as Squares5 } from '../../../assets/spinner/Squares5.svg';
import { ReactComponent as Squares6 } from '../../../assets/spinner/Squares6.svg';
import { ReactComponent as Squares7 } from '../../../assets/spinner/Squares7.svg';
import { ReactComponent as Squares8 } from '../../../assets/spinner/Squares8.svg';
import styles from './AzulSpinner.module.scss';

const squareComponents = [Squares0, Squares1, Squares2, Squares3, Squares4, Squares5, Squares6, Squares7, Squares8];

const AzulSpinner: FC = () => (
  <svg className={styles.spinner} height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
    {/* Maps through each group of svg squares that are then animated to give life to the spinner */}
    {squareComponents.map((SquareComponent, index) => (
      <g id={styles[`group${index}`]} key={`${SquareComponent.toString()}-${uuidv4()}`}>
        {SquareComponent && <SquareComponent />}
      </g>
    ))}
  </svg>
);

export default AzulSpinner;
