// Here are defined the section titles and its contents so you can manage them(add or remove any section/content)
export const SECTIONS_AND_CONTENTS = [
  { title: 'ANALYTICS', options: [{ title: 'Dashboard', path: 'dashboard' }] },
  {
    title: 'CONTENT',
    options: [
      { title: 'Background', path: 'background' },
      { title: 'Announcements', path: 'announcements' },
      { title: 'Area Info Related', path: 'area-info-related' },
      { title: 'Community', path: 'community' },
      { title: 'Training', path: 'training' },
      { title: 'Activities', path: 'activities' },
      { title: 'Social', path: 'social' },
    ],
  },
  {
    title: 'CONFIGURATION',
    options: [
      { title: 'Admin List', path: 'admin-list' },
      { title: 'Groups', path: 'groups' },
    ],
  },
];
