import { ElementType, FC, ReactNode, UIEvent, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Divider,
  FormControl,
  Icon,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../assets/ArrowDownGray.svg';
import { ReactComponent as BlueSearchIcon } from '../../assets/BlueSearch.svg';
import {
  accentBlue,
  backgroundWhite,
  destructiveRed2,
  disabledGray2,
  paragraphBaseline,
  primaryBlue,
} from '../../styles/partials/variables';
import Option from '../../type/option';
import { snakeCaseToPascalCase } from '../../utils/formatters/text';
import AzulCheckbox from './AzulCheckbox';
import { azulDesign } from './AzulDesignTheme';

interface AzulSelectProps {
  IconComponent?: ElementType;
  disabled?: boolean;
  error?: boolean;
  height?: string;
  helperText?: string;
  helperTextAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  id?: string;
  label?: ReactNode;
  labelId?: string;
  multiple?: boolean;
  options?: Option[];
  placeholder?: string;
  resetSearchOnClose?: boolean;
  searchBar?: boolean;
  useMuiIcons?: boolean;
  value?: string | string[];
  width?: string;
  onChange?: (event: SelectChangeEvent<string | string[]>, child: ReactNode) => void;
}

const AzulSelect: FC<AzulSelectProps> = ({
  IconComponent = ArrowDownIcon,
  disabled = false,
  error = false,
  height = '500px',
  helperText,
  helperTextAlign = 'start',
  id,
  label,
  labelId,
  multiple = false,
  options,
  placeholder,
  resetSearchOnClose = true,
  searchBar = false,
  useMuiIcons = false,
  value,
  width,
  onChange,
}: AzulSelectProps) => {
  const INITIAL_OPTIONS_LENGTH = 15;
  const [currentOptionsLength, setCurrentOptionsLength] = useState(INITIAL_OPTIONS_LENGTH);
  const [searchText, setSearchText] = useState<string>('');
  const containsText = (text: string, searchValue: string) =>
    text.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
  const filteredOptions = useMemo(
    () => options?.filter(({ label: optionLabel }) => containsText(optionLabel, searchText)),
    [options, searchText],
  );
  const [displayedOptions, setDisplayedOptions] = useState(filteredOptions?.slice(0, INITIAL_OPTIONS_LENGTH));

  const loadMoreItems = (event: UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop === parseInt(height, 10)) {
      const index = currentOptionsLength;
      const newIndex = index + 10;
      if (filteredOptions) {
        setCurrentOptionsLength(newIndex);
        setDisplayedOptions(displayedOptions?.concat(filteredOptions.slice(index, newIndex)));
      }
    }
  };
  const setLabelColor = () => {
    let labelColor = paragraphBaseline;
    if (error) labelColor = destructiveRed2;
    if (disabled) labelColor = disabledGray2;
    return labelColor;
  };

  const handleClose = () => (resetSearchOnClose ? () => setSearchText('') : undefined);

  useEffect(() => {
    setCurrentOptionsLength(INITIAL_OPTIONS_LENGTH);
    setDisplayedOptions(filteredOptions?.slice(0, INITIAL_OPTIONS_LENGTH));
  }, [filteredOptions]);

  return (
    <FormControl sx={{ width, fontFamily: 'Rubik !important' }}>
      <InputLabel
        id={labelId}
        sx={{
          color: setLabelColor(),
          fontFamily: 'Rubik',
          top: '-0.225rem',
          '&.Mui-focused': {
            color: error ? destructiveRed2 : primaryBlue,
          },
        }}
      >
        {label}
      </InputLabel>
      <ThemeProvider theme={azulDesign}>
        <Select<string | string[]>
          IconComponent={IconComponent}
          MenuProps={{
            autoFocus: false,
            PaperProps: {
              sx: {
                maxHeight: height,
                overflowY: 'auto',
              },
              onScroll: loadMoreItems,
            },
            sx: {
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: multiple ? backgroundWhite : accentBlue,
              },
            },
          }}
          disabled={disabled}
          error={error}
          id={id}
          label={label}
          labelId={labelId}
          multiple={multiple}
          placeholder={placeholder}
          renderValue={
            multiple
              ? (renderValue) => (renderValue as string[]).sort().join(', ')
              : (renderValue) => (
                  <Box alignItems='center' display='flex' flexDirection='row'>
                    {useMuiIcons && (
                      <ListItemIcon>
                        <Icon>{renderValue as string}</Icon>
                      </ListItemIcon>
                    )}
                    <ListItemText>{snakeCaseToPascalCase(renderValue as string)}</ListItemText>
                  </Box>
                )
          }
          value={value}
          onChange={onChange}
          onClose={handleClose}
        >
          {/* The select menu searchbar */}
          {searchBar && [
            <MenuItem disableTouchRipple key={`select_searchbar_${id}`}>
              <ListItemIcon>
                <BlueSearchIcon />
              </ListItemIcon>
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
                autoComplete='off'
                autoFocus
                fullWidth
                placeholder='Quick find'
                value={searchText}
                variant='standard'
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </MenuItem>,
            <Divider key={`select_divider_${id}`} sx={{ '&.MuiDivider-root': { margin: '0 1rem' } }} />,
          ]}
          {/* The select menu filtered options */}
          {displayedOptions && displayedOptions.length ? (
            displayedOptions.map(({ icon, label: optionLabel, value: optionValue }, index) => [
              <MenuItem key={`${optionValue}-${uuidv4()}`} value={optionValue}>
                {multiple && (
                  <ListItemIcon>
                    <AzulCheckbox
                      checked={(value as string[])?.findIndex((selectedValue) => selectedValue === optionValue) > -1}
                    />
                  </ListItemIcon>
                )}
                {icon && (
                  <ListItemIcon>
                    <Icon>{icon}</Icon>
                  </ListItemIcon>
                )}
                <ListItemText>{optionLabel}</ListItemText>
              </MenuItem>,
              index < displayedOptions.length - 1 && <Divider sx={{ '&.MuiDivider-root': { margin: '0 1rem' } }} />,
            ])
          ) : (
            // In case there is no results
            <MenuItem>
              <ListItemIcon />
              <ListItemText>No results found</ListItemText>
            </MenuItem>
          )}
        </Select>
      </ThemeProvider>
      <Typography
        component='span'
        sx={{
          color: setLabelColor(),
          fontSize: '.75rem',
          fontWeight: 400,
          letterSpacing: '0.008em',
          lineHeight: '1rem',
          margin: '0.25rem 1rem 0 1rem',
          textAlign: helperTextAlign,
        }}
        variant='body1'
      >
        {helperText}
      </Typography>
    </FormControl>
  );
};

export default AzulSelect;
