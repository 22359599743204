import { useNavigate } from 'react-router-dom';
import { Typography, ThemeProvider, Box, Button } from '@mui/material';
import { azulDesign } from '../../components/simpleComponents/AzulDesignTheme';

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/');
  };
  return (
    <ThemeProvider theme={azulDesign}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          rowGap: '2rem',
        }}
      >
        <Typography sx={{ textTransform: 'unset' }} variant='loginHeader'>
          {' '}
          Page Under Construction
        </Typography>
        <Typography variant='paragraph'>
          {' '}
          We expect the page to be fully functional soon. In the <br /> meantime, feel free to explore the other
          features
        </Typography>
        <Button variant='azulSecondary' onClick={goBack}>
          Back to home
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default NotFound;
