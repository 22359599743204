/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-underscore-dangle */

/* eslint-disable no-restricted-syntax */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { addSeconds, fromUnixTime, isAfter } from 'date-fns';
// TODO: replace functions for dayjs equivalents to avoid having two date libraries
import { setStatusCode } from '../store/errors';
import { logOut, setCredentials, setIsRefreshing } from '../store/session';
import { selectSessionCredentials } from '../store/session/selectors';
import { store } from '../store/store';
import { Credentials } from '../type/credentials';
import { HttpStatusCode } from '../utils/constants/httpStatusCode';
import { authService } from './authService';
import * as sentryService from './sentryService';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_TIMEOUT: number = 600000 * 1.01;
export const BASE_HEADERS = {
  'Content-Type': 'application/json',
};
const LOCAL_STORAGE_CREDENTIALS_KEY = 'credentials';

const selectSessionToken = (state: any) => state?.session?.credentials?.token;
const tokenIsRefreshing = (state: any) => state?.session?.isRefreshing;

export interface ServiceOptions {
  attributes?: string[];
  headers?: unknown;
  include?: any[];
  limit?: number;
  offset?: number;
  order?: [string, 'ASC' | 'DESC'][] | [string, string, 'ASC' | 'DESC'][];
  params?: unknown;
  where?: any;
  onUploadProgress?: (data: ProgressEvent) => void;
}

export interface ResponseData<T> {
  data: T;
  message?: string;
  status?: number;
}

export interface ListResponseData<T> {
  count: number;
  data: T[];
  limit: number;
  offset: number;
  message?: string;
  order?: any;
}

export function prepareParams(options?: ServiceOptions | any): {
  [key: string]: string;
} {
  const params: { [key: string]: string } = {};
  if (options != null) {
    for (const key in options) {
      if ((options as any)[key]) {
        params[key] = JSON.stringify((options as any)[key]);
      }
    }
  }
  return params;
}

export class BaseService<T> {
  controllerName = 'default';

  baseUrl: string | undefined;

  apiTimeOut: number = API_TIMEOUT;

  _api: AxiosInstance | undefined;

  get api() {
    if (this._api == null || this.baseUrl == null) {
      this.baseUrl = API_BASE_URL;
      if (this.baseUrl == null) {
        console.warn('Your REACT_APP_API_BASE_URL is not defined, make sure it is correctly set in .env');
      }
      this._api = axios.create({
        timeout: this.apiTimeOut,
        headers: BASE_HEADERS,
        baseURL: `${this.baseUrl}`,
      });
      if (!(process.env.REACT_APP_DEVELOPMENT_MODE === 'true')) {
        this._api.interceptors.request.use(this.handleRequest);
        this._api.interceptors.response.use(undefined, this.handleExpiredToken);
      }
      this._api.interceptors.response.use(this.handleStatusCodeSuccess, this.handleStatusCodeError);
    }
    return this._api;
  }

  logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_CREDENTIALS_KEY);
    store.dispatch(logOut());
  };

  refreshToken = async (credentials: Credentials) => {
    if (!tokenIsRefreshing(store.getState())) {
      try {
        store.dispatch(setIsRefreshing(true));
        const { data: newCredentials } = await authService.refresh(credentials);
        localStorage.setItem(LOCAL_STORAGE_CREDENTIALS_KEY, JSON.stringify(newCredentials));
        store.dispatch(setCredentials(newCredentials));
      } catch (err) {
        console.error(err);
        this.logout();
      } finally {
        store.dispatch(setIsRefreshing(false));
      }
    }
  };

  handleRequest = async (config: any) => {
    try {
      const credentials = selectSessionCredentials(store.getState());
      if (!credentials) {
        throw new Error('No Credentials found');
      }
      const sessionExpiration = fromUnixTime(credentials.expires);
      const now = new Date();
      const timeBeforeRefresh = 900;
      const refreshThreshold = addSeconds(now, timeBeforeRefresh);
      if (isAfter(refreshThreshold, sessionExpiration)) {
        await this.refreshToken(credentials);
      }
    } catch (err) {
      console.error(err);
      this.logout();
    }
    return config;
  };

  handleExpiredToken = (error: AxiosError) => {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      console.error(error);
      this.logout();
    }
    return Promise.reject(error);
  };

  handleStatusCodeSuccess = (response: AxiosResponse) => {
    if (response.status !== HttpStatusCode.OK) {
      store.dispatch(setStatusCode(response));
    }
    return response;
  };

  handleStatusCodeError = (error: AxiosError) => {
    const credentials = selectSessionCredentials(store.getState());
    sentryService.api(error, credentials);

    if (error.response?.status !== HttpStatusCode.OK) {
      store.dispatch(setStatusCode(error.response as AxiosResponse));
    }

    return Promise.reject(error);
  };

  getDefaultHeaders(): any {
    const token =
      process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
        ? process.env.REACT_APP_DEVELOPMENT_TOKEN
        : selectSessionToken(store.getState());

    let defaultHeaders: any = {
      ...BASE_HEADERS,
    };
    if (token) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      };
    }
    return defaultHeaders;
  }

  async getAll(options?: ServiceOptions): Promise<ListResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ListResponseData<T>>(`/${this.controllerName}`, { params });
    return data;
  }

  async getById(id: number | string, options?: ServiceOptions): Promise<ResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<T>>(`/${this.controllerName}/${id}`, { params });
    return data;
  }

  async get(options?: ServiceOptions): Promise<ResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<T>>(`/${this.controllerName}`, { params });
    return data;
  }

  async update(id: number | string, bodyData: any, options?: ServiceOptions): Promise<ResponseData<T>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.put<ResponseData<T>>(`/${this.controllerName}/${id}`, JSON.stringify(bodyData), {
      params,
    });
    return data;
  }

  async create(bodyData: any): Promise<ResponseData<T>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post<ResponseData<T>>(`/${this.controllerName}`, JSON.stringify(bodyData));
    return data;
  }

  async patch(id: number | string, bodyData: any): Promise<ResponseData<T>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.patch<ResponseData<T>>(`/${this.controllerName}/${id}`, JSON.stringify(bodyData));
    return data;
  }

  async delete(id: number | string): Promise<ResponseData<T>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.delete<ResponseData<T>>(`/${this.controllerName}/${id}`);
    return data;
  }
}
