import { RgbColor, colord } from 'colord';
import { ChangeEvent, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box, Container, PaperProps, Popover, PopoverOrigin, TextField, Typography } from '@mui/material';
import styles from './ColorPicker.module.scss';

interface ColorPickerProps {
  anchorEl: Element | ((element: Element) => Element) | null;
  color: string;
  open: boolean;
  anchorOrigin?: PopoverOrigin;
  paperProps?: PaperProps;
  pickerId?: string;
  transformOrigin?: PopoverOrigin;
  onChange: (newColor: string) => void;
  onClose: () => void;
}

type RGBKeys = 'r' | 'g' | 'b';

export const ColorPicker = ({
  anchorEl,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  color,
  open,
  paperProps,
  pickerId,
  transformOrigin,
  onChange,
  onClose,
}: ColorPickerProps) => {
  const [hexInput, setHexInput] = useState(color);
  const { r, g, b } = colord(color).toRgb();
  const [rgbInput, setRgbInput] = useState({
    r: String(r),
    g: String(g),
    b: String(b),
  });

  useEffect(() => {
    setHexInput(color);
    const { r: rUpdated, g: gUpdated, b: bUpdated } = colord(color).toRgb();
    setRgbInput({
      r: String(rUpdated),
      g: String(gUpdated),
      b: String(bUpdated),
    });
  }, [color]);

  const handleChangeColorPicker = (newColor: string) => {
    setHexInput(newColor);
    const { r: rChanged, g: gChanged, b: bChanged } = colord(newColor).toRgb();
    const rgbStringObject = {
      r: String(rChanged),
      g: String(gChanged),
      b: String(bChanged),
    };
    setRgbInput({ ...rgbStringObject });
    onChange(newColor);
  };

  const handleChangeHexInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let hexValue = event.target.value;
    let cleanHex = '';
    const regexp = /[A-Fa-f0-9]/gi;

    if (hexValue[0] === '#') {
      hexValue = hexValue.slice(1);
    }

    cleanHex = hexValue.match(regexp)?.join('') || '';
    cleanHex = `#${cleanHex.toLowerCase()}`;

    if (colord(cleanHex).isValid()) {
      onChange(cleanHex);
    }

    setHexInput(cleanHex);
  };

  const handleChangeRgbInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, id } = event.target;
    let validValue = value;

    if (value.length > 3) {
      validValue = validValue.slice(0, 3);
    }

    if (Number(validValue) < 0) {
      validValue = '0';
    } else if (Number(validValue) > 255) {
      validValue = validValue.slice(0, 2);
    }

    const newRgbValue = { ...rgbInput, [id as RGBKeys]: validValue };
    const rgbToValidate: RgbColor = {
      r: Number(newRgbValue.r),
      g: Number(newRgbValue.g),
      b: Number(newRgbValue.b),
    };

    if (colord(rgbToValidate).isValid()) {
      const hexRepresentation = colord(rgbToValidate).toHex();
      onChange(hexRepresentation);
    }

    setRgbInput((prev) => ({ ...prev, [id as RGBKeys]: validValue }));
  };

  return (
    <Popover
      PaperProps={{
        elevation: 24,
        className: styles.colorPickerPaper,
        ...paperProps,
      }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      id={pickerId}
      open={open}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <Container className='custom-color-picker-layout' disableGutters>
        <HexColorPicker className={styles.reactColorful} color={color} onChange={handleChangeColorPicker} />
        {/* TODO: STYLE HEX INPUT AND ADD RGB INPUTS, USE COLORD FOR TRANSFORMING VALUES */}
        <Box columnGap='8px' display='flex' paddingX='2px'>
          <Box alignItems='flex-start' display='flex' flexDirection='column' rowGap='4px'>
            {/* ===== HEX Inputs ===== */}
            <Typography variant='colorPickerInputLabel'>Hex</Typography>
            <TextField
              className='colorPickerInput'
              inputProps={{ maxLength: 7 }}
              value={hexInput}
              variant='outlined'
              onChange={handleChangeHexInput}
            />
          </Box>
          {/* ===== RGB Inputs ===== */}
          <Box
            alignItems='flex-start'
            display='flex'
            flexDirection='column'
            maxWidth='64px'
            minWidth='64px'
            rowGap='4px'
          >
            <Typography variant='colorPickerInputLabel'>R</Typography>
            <TextField
              className='colorPickerInput'
              id='r'
              inputProps={{ max: 255, min: 0, className: styles.colorPickerRgbInput }}
              type='number'
              value={rgbInput.r}
              onChange={handleChangeRgbInput}
            />
          </Box>
          <Box
            alignItems='flex-start'
            display='flex'
            flexDirection='column'
            maxWidth='64px'
            minWidth='64px'
            rowGap='4px'
          >
            <Typography variant='colorPickerInputLabel'>G</Typography>
            <TextField
              className='colorPickerInput'
              id='g'
              inputProps={{ max: 255, min: 0, className: styles.colorPickerRgbInput }}
              type='number'
              value={rgbInput.g}
              onChange={handleChangeRgbInput}
            />
          </Box>
          <Box
            alignItems='flex-start'
            display='flex'
            flexDirection='column'
            maxWidth='64px'
            minWidth='64px'
            rowGap='4px'
          >
            <Typography variant='colorPickerInputLabel'>B</Typography>
            <TextField
              className='colorPickerInput'
              id='b'
              inputProps={{ max: 255, min: 0, className: styles.colorPickerRgbInput }}
              type='number'
              value={rgbInput.b}
              onChange={handleChangeRgbInput}
            />
          </Box>
        </Box>
      </Container>
    </Popover>
  );
};
