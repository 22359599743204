import { Fragment } from 'react';
import { Box, Skeleton } from '@mui/material';

const LoadingSkeleton = () => (
  <Fragment>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
      <Skeleton height={32} variant='circular' width={32} />
      <Skeleton height={24} sx={{ marginLeft: '0.8rem' }} variant='rounded' width='90%' />
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
      <Skeleton height={32} variant='circular' width={32} />
      <Skeleton height={24} sx={{ marginLeft: '0.8rem' }} variant='rounded' width='90%' />
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Skeleton height={32} variant='circular' width={32} />
      <Skeleton height={24} sx={{ marginLeft: '0.8rem' }} variant='rounded' width='90%' />
    </Box>
  </Fragment>
);

export default LoadingSkeleton;
