import { ChangeEvent, FC, MouseEvent } from 'react';
import { Pagination } from '@mui/material';

type AzulPaginationProps = {
  numberOfPages: number; // total amount of pages to display the table's data
  currentPage: number; // currently selected page number in the table
  onPageChange: (event: ChangeEvent<unknown> | MouseEvent<HTMLButtonElement>, page: number) => void;
};

const AzulPagination: FC<AzulPaginationProps> = ({ numberOfPages, currentPage, onPageChange }: AzulPaginationProps) => (
  <Pagination
    count={numberOfPages}
    page={currentPage + 1}
    shape='rounded'
    showFirstButton
    showLastButton
    siblingCount={2}
    onChange={(event: ChangeEvent<unknown>, page: number) => onPageChange(event, page - 1)}
  />
);
export default AzulPagination;
