import { Dayjs } from 'dayjs';
import { FC, ReactNode } from 'react';
import {
  accentBlue,
  destructiveRed2,
  disabledGray1,
  disabledGray2,
  focusPurple,
  paragraphBaseline,
  paragraphPlaceholder,
  primaryBlue,
} from 'styles/partials/variables';
import { DateValidationError } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickerChangeHandler } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue';

type AzulDatePickerProps = {
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: ReactNode;
  value?: Dayjs | null;
  width?: string;
  onChange?: PickerChangeHandler<Dayjs | null, DateValidationError>;
};

const AzulDatePicker: FC<AzulDatePickerProps> = ({
  disableFuture = false,
  disablePast = false,
  disabled = false,
  error = undefined,
  helperText,
  label,
  value,
  width,
  onChange,
}: AzulDatePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      disableFuture={disableFuture}
      disableHighlightToday
      disablePast={disablePast}
      disabled={disabled}
      label={label}
      reduceAnimations
      showDaysOutsideCurrentMonth
      slotProps={{
        textField: {
          error,
          helperText,
        },
        desktopPaper: {
          sx: {
            fontFamily: 'Rubik',
            borderRadius: '8px',
            boxShadow: '0px 5px 10px rgba(29, 63, 156, 0.2)',
            '& .MuiPickersDay-dayWithMargin': {
              color: paragraphBaseline,
              fontWeight: 400,
              '&.Mui-focusVisible': {
                bgcolor: accentBlue,
                outline: `1px solid ${focusPurple}`,
                '&.Mui-selected': {
                  bgcolor: primaryBlue,
                },
              },
              '&.Mui-selected': {
                bgcolor: primaryBlue,
              },
              '&.Mui-disabled': {
                color: paragraphPlaceholder,
              },
            },
            '& .MuiPickersDay-dayWithMargin:focus.Mui-selected': {
              bgcolor: primaryBlue,
            },
            '& .MuiPickersDay-dayWithMargin:hover': {
              bgcolor: accentBlue,
            },
            '& .MuiDayCalendar-weekDayLabel': {
              color: paragraphBaseline,
              fontWeight: 500,
            },
            '& .MuiPickersCalendarHeader-label': {
              color: paragraphBaseline,
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',
              cursor: 'default',
            },
          },
        },
      }}
      slots={{}}
      sx={{
        fontFamily: 'Rubik',
        height: '3rem',
        width: { width },
        '& .MuiInputLabel-root': {
          color: paragraphBaseline,
          fontFamily: 'Rubik',
          top: '-0.225rem',
          zIndex: 1,
          '&.Mui-focused': {
            color: primaryBlue,
          },
          '&.Mui-error': {
            color: destructiveRed2,
          },
          '&.Mui-disabled': {
            color: disabledGray2,
          },
        },
        '& .MuiOutlinedInput-input': {
          fontSize: '1rem',
          height: '1.5rem',
          letterSpacing: '0.002em',
          padding: '.75rem',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: paragraphBaseline,
            borderRadius: '8px',
          },
          '&.Mui-focused fieldset': {
            borderColor: primaryBlue,
          },
          '&.Mui-error fieldset': {
            borderColor: destructiveRed2,
          },
          '&.Mui-disabled fieldset': {
            borderColor: disabledGray2,
            bgcolor: disabledGray1,
            zIndex: -1,
          },
        },
        '& .MuiFormHelperText-root': {
          color: paragraphBaseline,
          fontFamily: 'Rubik',
          '&.Mui-error': {
            color: destructiveRed2,
          },
          '&.Mui-disabled': {
            color: disabledGray2,
          },
        },
      }}
      value={value}
      views={['day']}
      onChange={onChange}
    />
  </LocalizationProvider>
);

export default AzulDatePicker;
