import SideMainMenu from 'components/SideMainMenu/SideMainMenu';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { selectIsAuth } from 'store/session/selectors';
import { useAppSelector } from 'utils/hooks/storeHooks';

// This component gives us the views general layout and allows us to connect components/views through the outlet
const Layout = () => {
  const isAuthenticatedFromStore = useAppSelector(selectIsAuth);
  // to determine if the user is logged in or not (either in production or via dev mode true) and therefore show/hide the sidemenu as part of the layout
  const isAuthenticated = process.env.REACT_APP_DEVELOPMENT_MODE === 'true' || isAuthenticatedFromStore;

  return (
    <Fragment>
      {isAuthenticated && <SideMainMenu />}
      <Outlet />
    </Fragment>
  );
};

export default Layout;
