import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Collapse, List } from '@mui/material';
import styles from '../SideMainMenu.module.scss';
import ListIndex from './ListIndex';
import ListSection from './ListSection';

type MenuSectionProps = {
  title: string;
  options: { title: string; path: string }[];
};

const MenuSection = ({ title, options }: MenuSectionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const toggleSection = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box className={styles.section_container}>
      <ListSection isExpanded={isExpanded} sectionName={title} onClickFunction={toggleSection} />
      <Collapse in={isExpanded} style={{ marginBottom: '2rem' }} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {options.map(({ path, title: optionTitle }) => (
            <ListIndex indexName={optionTitle} key={`${optionTitle}-${uuidv4()}`} url={path} />
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default MenuSection;
