import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { neutralGray1, neutralGray3 } from 'styles/partials/variables';
import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as FailureTickSVG } from '../../assets/FailureTick.svg';
import { ReactComponent as SuccessTickSVG } from '../../assets/SuccessTick.svg';
import snackContext from '../../store/features/contextSnackbar';

export type SnackbarMessage = {
  key: number;
  message: string;
  successful: boolean;
  customErrorMessage?: string;
};

const SimpleSnackbar = () => {
  const { snackPack, setSnackPack } = useContext(snackContext);
  const [isSnackOpen, setIsSnackOpen] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setIsSnackOpen(true);
    } else if (snackPack.length && messageInfo && isSnackOpen) {
      // Close an active snack when a new one is added
      setIsSnackOpen(false);
    }
  }, [snackPack, messageInfo, isSnackOpen, setSnackPack]);

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackOpen(false);
  };

  return (
    <Snackbar
      ContentProps={{ sx: { bgcolor: neutralGray3, borderRadius: '8px', color: neutralGray1 } }}
      TransitionProps={{ onExited: handleExited }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      key={messageInfo ? messageInfo.key : undefined}
      message={
        messageInfo?.successful ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <SuccessTickSVG />
            &nbsp;&nbsp;&nbsp;{messageInfo?.message}
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <FailureTickSVG />
            &nbsp;&nbsp;&nbsp; {messageInfo?.customErrorMessage || 'There has been an error'}.
          </div>
        )
      }
      open={isSnackOpen}
      onClose={handleClose}
    />
  );
};

export default SimpleSnackbar;
