import { FC } from 'react';
import AzulContainer from '../../components/simpleComponents/AzulContainer';
import UnderWork from '../../components/underWork/UnderWork';

const Training: FC = () => (
  <AzulContainer>
    <UnderWork pageName='Training Page' />
  </AzulContainer>
);

export default Training;
