import { FC, HTMLInputTypeAttribute, ReactNode, ChangeEvent } from 'react';
import { TextField, ThemeProvider } from '@mui/material';
import { azulDesign } from './AzulDesignTheme';

type AzulInputProps = {
  defaultValue?: unknown;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  helperTextAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  id?: string | undefined;
  label?: ReactNode;
  margin?: string;
  placeholder?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  value?: string;
  width?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const AzulInput: FC<AzulInputProps> = ({
  defaultValue,
  disabled = false,
  error,
  helperText,
  helperTextAlign = 'start',
  id,
  label,
  margin,
  placeholder,
  required = false,
  type,
  value,
  width,
  onChange,
}: AzulInputProps) => (
  <ThemeProvider theme={azulDesign}>
    <TextField
      autoComplete='off'
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      helperText={helperText}
      id={id}
      label={label}
      placeholder={placeholder}
      required={required}
      sx={{
        margin,
        width: { width },
        '.MuiFormHelperText-root': {
          textAlign: `${helperTextAlign}`,
        },
      }}
      type={type}
      value={value}
      variant='outlined'
      onChange={onChange}
    />
  </ThemeProvider>
);

export default AzulInput;
