import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

type AzulContainerProps = {
  children: ReactNode;
};

const AzulContainer: FC<AzulContainerProps> = ({ children }: AzulContainerProps) => (
  <Box maxWidth='75.75rem' pl='346px' pr='186px' py='40px' textAlign='left'>
    {children}
  </Box>
);

export default AzulContainer;
