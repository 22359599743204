// Formats the text casing, used for the backend groupnames, example: product designers --> Product Designers
export const toPascalCase = (textToFormat: string) =>
  textToFormat
    ?.match(/[a-zA-Z0-9/]+/g)
    ?.map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

export const snakeCaseToPascalCase = (str: string) =>
  str
    .split('_')
    .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(' ');
