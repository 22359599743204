import { FC } from 'react';
import { AudienceType } from 'utils/constants/audienceType';
import { Box, Typography } from '@mui/material';
import { accentBlue } from '../../styles/partials/variables';
import { toPascalCase } from '../../utils/formatters/text';
import styles from '../../views/groups/Groups.module.scss';
import MoreSettingsBtn from '../moreSettingsBtn/MoreSettingsBtn';
import { StyledCard, StyledCardContent, StyledCardHeader } from './StyledComponents';

type GroupCardProps = {
  groupId: number;
  groupName: string;
  totalMembers: number;
};

const GroupCard: FC<GroupCardProps> = ({ groupId, groupName, totalMembers }: GroupCardProps) => (
  <StyledCard>
    <StyledCardHeader action={<MoreSettingsBtn crudId={groupId} label={AudienceType.Group} name={groupName} />} />

    <Box display='flex' flexDirection='column' height='103px' justifyContent='space-between'>
      <StyledCardContent>
        <strong className={styles.H3}>{toPascalCase(groupName)}</strong>
      </StyledCardContent>

      <Typography bgcolor={accentBlue} pb={1} pl={2} pt={1} textAlign='left'>
        <span className={styles.P2}>{totalMembers} members</span>
      </Typography>
    </Box>
  </StyledCard>
);

export default GroupCard;
