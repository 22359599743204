import { MouseEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Avatar, Chip, Divider, Icon, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { accentBlue, paragraphBaseline, primaryBlue, primaryHoverBlue1 } from '../../styles/partials/variables';
import Option from '../../type/option';
import { Icons } from '../../utils/constants/icons';
import { azulDesign } from '../simpleComponents/AzulDesignTheme';

type AvatarChipProps = {
  options: Option[];
  imgSrc?: string;
  name?: string;
  onClickOption: (value: string) => void;
};

const AvatarChip = ({ options, imgSrc, name, onClickOption }: AvatarChipProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <ThemeProvider theme={azulDesign}>
      <Chip
        avatar={
          imgSrc ? (
            <Avatar
              alt={name || 'Profile chip'}
              src={imgSrc}
              sx={{
                '&.MuiAvatar-root': {
                  height: '32px',
                  width: '32px',
                },
              }}
            />
          ) : (
            <Avatar
              alt={name || 'Profile chip'}
              sx={{
                '&.MuiAvatar-root': {
                  bgcolor: primaryBlue,
                  color: `${isMenuOpen ? primaryHoverBlue1 : accentBlue}`,
                  height: '32px',
                  width: '32px',
                },
              }}
            >
              {name?.charAt(0)}
            </Avatar>
          )
        }
        color={isMenuOpen ? 'primaryHoverBlue1' : 'accentBlue'}
        label={<Icon>{isMenuOpen ? Icons.ExpandLess : Icons.ExpandMore}</Icon>}
        variant='azulAvatarChip'
        onClick={handleClick}
      />

      <Menu anchorEl={menuAnchorEl} id='user-menu' open={isMenuOpen} onClose={handleClose}>
        {options.map(({ icon, label, value }, index) => [
          <MenuItem
            disableRipple
            key={`${label}-${uuidv4()}`}
            sx={{ color: paragraphBaseline }}
            onClick={() => {
              onClickOption(value);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>,
          index < options.length - 1 && <Divider sx={{ '&.MuiDivider-root': { margin: '0 1rem' } }} />,
        ])}
      </Menu>
    </ThemeProvider>
  );
};

export default AvatarChip;
