import { SynkronUser } from 'type/user';
import { User } from '@sentry/react';

// Parser to rename the "name" property of User[] to "fullName" as in SynkronUser[]
export const generalUser = (usersArray: SynkronUser[] | User[]) =>
  // eslint-disable-next-line func-names
  JSON.parse(JSON.stringify(usersArray), function (key, value) {
    if (key === 'name') {
      // eslint-disable-next-line react/no-this-in-sfc
      this.fullName = value;
      return; // if return undefined, orignal property will be removed
    }
    // eslint-disable-next-line consistent-return
    return value;
  });
