/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, PropsWithChildren, ReactNode } from 'react';
import { accentBlue, focusPurple, paragraphPlaceholder } from 'styles/partials/variables';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';
import { azulDesign } from './AzulDesignTheme';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
}

function BootstrapDialogTitle({ children, ...other }: DialogTitleProps) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

type Props = {
  modalContent?: ReactNode;
  modalActions?: ReactNode;
  onClose?: () => void;
  open: boolean;
  padding?: string;
  title?: string;
  titleHelperText?: string;
};

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  modalContent,
  modalActions,
  onClose,
  open,
  padding,
  title = 'Modal Title',
  titleHelperText,
}: PropsWithChildren<Props>) => (
  <ThemeProvider theme={azulDesign}>
    {/* The node that triggers the modal/dialog */}
    {children}

    {/* The main container for the modal */}
    <Dialog
      aria-labelledby='customized-dialog-title'
      open={open}
      sx={{ '& .MuiPaper-root': { padding } }}
      transitionDuration={0}
      onClose={onClose}
    >
      {/* The icon for closing the modal */}
      {onClose && (
        <IconButton
          TouchRippleProps={{ style: { color: accentBlue } }}
          aria-label='close'
          disableTouchRipple
          sx={{
            // color: (theme) => theme.palette.grey[500],
            padding: '.75rem',
            position: 'absolute',
            right: 16,
            top: 16,
            '&:hover': {
              backgroundColor: accentBlue,
            },
            '&:focus-visible': {
              borderRadius: '8px',
              outline: `2px solid ${focusPurple}`,
            },
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}

      {/* The modal title */}
      <BootstrapDialogTitle id='customized-dialog-title'>{title}</BootstrapDialogTitle>

      <Typography
        sx={{
          display: titleHelperText ? '' : 'none',
          color: paragraphPlaceholder,
          letterSpacing: '0.004em',
          padding: '0.5rem 0 0 0',
          textAlign: 'left',
        }}
        variant='paragraph'
      >
        {titleHelperText}
      </Typography>

      {/* The modal main content */}
      {modalContent && <DialogContent sx={{ padding: '3.5rem 0 0 0' }}>{modalContent}</DialogContent>}

      {/* The modal actions */}
      {modalActions && <DialogActions>{modalActions}</DialogActions>}
    </Dialog>
  </ThemeProvider>
);

export default Modal;
