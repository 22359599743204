import { ReactNode } from 'react';
import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import { azulDesign } from './AzulDesignTheme';

type AzulEmptyStateProps = {
  buttonLabel?: string;
  customButton?: ReactNode;
  height?: string | number;
  imgAriaLabel?: string;
  imgHeight?: string | number;
  imgSrc?: string;
  imgWidth?: string | number;
  subTitle?: string;
  title?: string;
  buttonAction?: () => void;
};

const AzulEmptyState = ({
  buttonLabel,
  customButton,
  height,
  imgAriaLabel,
  imgSrc,
  imgHeight,
  imgWidth,
  subTitle = 'Try creating a new one!',
  title = 'None has been made yet.',
  buttonAction,
}: AzulEmptyStateProps) => (
  <ThemeProvider theme={azulDesign}>
    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height, justifyContent: 'center' }}>
      <img aria-label={imgAriaLabel} height={imgHeight} src={imgSrc} width={imgWidth} />
      <Typography variant='h2'>{title}</Typography>
      <Typography variant='paragraph'>{subTitle}</Typography>
      {customButton ||
        ((buttonAction || buttonLabel) && (
          <Button sx={{ margin: '1.5rem 0 0 0' }} variant='azulPrimary' onClick={buttonAction}>
            {buttonLabel}
          </Button>
        ))}
    </Box>
  </ThemeProvider>
);

export default AzulEmptyState;
