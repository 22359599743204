import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BlueAddButton = styled(Button)(() => ({
  backgroundColor: '#2a5bdf',
  border: 'none',
  borderRadius: '4px',
  color: '#ffffff',
  fontSize: '16px',
  fontWeight: 600,
  height: '40px',
  letterSpacing: '0.01em',
  padding: '8px 16px 8px 16px',
  textTransform: 'none',
  marginLeft: '1rem',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#2046ad',
  },
  '&:focus-visible': {
    outline: '2px solid #b770fe',
  },
}));
