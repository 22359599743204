import { FC, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, Menu, Typography } from '@mui/material';
import { ReactComponent as EditSVG } from '../../assets/Edit.svg';
import { primaryBlue } from '../../styles/partials/variables';
import styles from '../../views/groups/Groups.module.scss';
import DeleteModal from '../deleteModal/DeleteModal';
import { StyledIconButton, StyledMenuItem } from './StyledComponents';

type Props = {
  crudId: number; // The id that will be used in the delete and edit services
  label: string; // The name of what will be involved on the edit or delete operation (i.e. background)
  audienceType?: 'groups' | 'individuals'; // Used to validate the audience type for announcement services
  blueStyle?: boolean; // Helps determine the component styling accordingly
  name?: string; // The name of the element to delete (i.e. groupname), used for the snackbar
  noEdit?: boolean; // To determine wether the edit option is available for display or not
};

const MoreSettingsBtn: FC<Props> = ({ crudId, label, audienceType, blueStyle, name, noEdit }: Props) => {
  // To navigate other parte of the app through the routing
  const navigate = useNavigate();

  // States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Handlers for opening and closing the menu
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = () => {
    switch (label) {
      case 'Group':
        navigate(`/groups/edit/${crudId}`);
        break;
      case 'Announcement':
        navigate(`/announcements/edit/${audienceType}/${crudId}`);
        break;
      case 'Background':
        navigate(`/background/edit/${audienceType}/${crudId}`);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      {blueStyle ? (
        <StyledIconButton
          aria-label='Settings'
          disableTouchRipple
          sx={{ color: primaryBlue, padding: '2px' }}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </StyledIconButton>
      ) : (
        <StyledIconButton aria-label='Settings' disableTouchRipple onClick={handleClick}>
          <MoreVertIcon />
        </StyledIconButton>
      )}

      <Menu
        MenuListProps={{ sx: { padding: 0 } }}
        PaperProps={{ sx: { borderRadius: '8px' } }}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        elevation={1}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={handleClose}
      >
        {!noEdit && (
          <Fragment>
            <StyledMenuItem disableTouchRipple onClick={handleNavigate}>
              <EditSVG style={{ marginRight: '.75rem' }} />

              <Typography className={`${styles.P1} ${styles.pointer}`}>Edit {label}</Typography>
            </StyledMenuItem>

            <Divider sx={{ '&.MuiDivider-middle': { margin: '0 1rem' } }} variant='middle' />
          </Fragment>
        )}

        <DeleteModal crudId={crudId} label={label} name={name} />
      </Menu>
    </Fragment>
  );
};

export default MoreSettingsBtn;
