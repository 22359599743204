export enum TextToolSetOptions {
  TextColor = 'textColor',
  TextSize = 'textSize',
  Bold = 'bold',
  Alignment = 'alignment',
}

export enum TextBox {
  Title = 'title',
  Subtitle = 'subtitle',
}
