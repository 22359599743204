import { useNavigate } from 'react-router-dom';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { closeSession } from '../../store/session/thunks';
import { Icons } from '../../utils/constants/icons';
import { useAppDispatch } from '../../utils/hooks/storeHooks';
import AvatarChip from '../avatarChip/AvatarChip';
import { azulDesign } from '../simpleComponents/AzulDesignTheme';

type SectionHeaderProps = {
  name?: string;
};

const profileMenuOptions = [{ icon: Icons.Logout, label: 'Exit Admin View', value: 'login' }];

const SectionHeader = ({ name }: SectionHeaderProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnClickOption = (value: string) => {
    switch (value) {
      case 'login':
        dispatch(closeSession());
        navigate(`/${value}`);
        break;
      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={azulDesign}>
      <Box display='flex' justifyContent='flex-end'>
        <AvatarChip options={profileMenuOptions} onClickOption={(value) => handleOnClickOption(value)} />
      </Box>

      {name && <Typography variant='viewHeader'>{name}</Typography>}
    </ThemeProvider>
  );
};

export default SectionHeader;
