/* eslint-disable camelcase */
import { useState, FC, MouseEvent, ChangeEvent, useEffect } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  ThemeProvider,
  Box,
} from '@mui/material';
import { primaryBlue } from '../../../styles/partials/variables';
import { GroupMember } from '../../../type/group';
import DeleteModal from '../../deleteModal/DeleteModal';
import { azulDesign } from '../../simpleComponents/AzulDesignTheme';
import AzulPagination from '../../simpleComponents/AzulPagination';

type StyledMembersTableProps = {
  count: number; // The lenght of the data, equivalent to the number of rows
  crudId: number; // The ID of the group whose users are being displayed on the table
  data: GroupMember[]; // The fetched users' data for the current group
};

// The table receives the data to display, and the count is the number of rows, or data.length
export const StyledMembersTable: FC<StyledMembersTableProps> = ({ count, crudId, data }: StyledMembersTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  // Changes the layout when changing page number
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null | ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  // Calculates the number of rows on each page
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Goes to the first page when data changes
  useEffect(() => {
    setPage(0);
  }, [data]);

  return (
    <ThemeProvider theme={azulDesign}>
      {/* A small summary text above the table */}
      <Box alignItems='center' display='flex' height='60px' justifyContent='space-between' mt='12px'>
        <Typography variant='paragraph14'>
          Showing {count < rowsPerPage ? count : rowsPerPage - emptyRows} of {count} members
        </Typography>
        <TablePagination
          ActionsComponent='noindex'
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: false,
            IconComponent: KeyboardArrowDown,
          }}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: count }]}
          sx={{ width: 1100 }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {/* The main table container */}
      <TableContainer component={Paper}>
        {' '}
        <Table aria-label='custom pagination table'>
          {/* The table's first row with the column titles */}
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>FULL NAME</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {/* Where the searched users data information is actually displayed */}
          <TableBody>
            {/* TO DO: ask backend to set desrtuctured variables to camel case, added eslint comment on top since it complains because of this */}
            {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(
              ({ full_name, id, member_email }) => (
                <TableRow key={id}>
                  <TableCell align='center' component='th' scope='row' style={{ width: 64 }}>
                    {/* The users' intial that shows on the first column of the table */}
                    <Typography variant='avatarInitial'>{full_name.charAt(0)}</Typography>
                  </TableCell>
                  <TableCell>{member_email}</TableCell>
                  <TableCell>{full_name}</TableCell>
                  <TableCell align='right' sx={{ width: 100, color: primaryBlue }}>
                    <DeleteModal crudId={crudId} endIcon label='Member' userEmail={member_email} />
                  </TableCell>
                </TableRow>
              ),
            )}
            {count === 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell align='center' colSpan={6}>
                  No results found
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* The above table's pagination with a custom styling */}
      <Box display='flex' justifyContent='center' mt='24px' width='100%'>
        <AzulPagination
          currentPage={page}
          numberOfPages={Math.ceil(count / rowsPerPage)}
          onPageChange={handleChangePage}
        />
      </Box>
    </ThemeProvider>
  );
};
