export const DURATIONS = [
  { label: '1 Day', value: '1' },
  { label: '2 Days', value: '2' },
  { label: '3 Days', value: '3' },
  { label: '4 Days', value: '4' },
  { label: '5 Days', value: '5' },
  { label: '1 Week', value: '7' },
  { label: '2 Weeks', value: '14' },
  { label: '3 Weeks', value: '21' },
];

export const TIMEZONES = [
  /* ===== AR ===== */
  { label: '(UTC-03:00) America/Buenos Aires', value: 'America/Argentina/Buenos_Aires' },
  /* ===== DO ===== */
  { label: '(UTC-04:00) America/Santo Domingo', value: 'America/Santo_Domingo' },
  /* ===== GT ===== */
  { label: '(UTC-06:00) America/Guatemala', value: 'America/Guatemala' },
  /* ===== IN ===== */
  { label: '(UTC+05:30) Asia/Kolkata', value: 'Asia/Kolkata' },
  /* ===== MX ===== */
  { label: '(UTC-07:00) America/Tijuana', value: 'America/Tijuana' },
  { label: '(UTC-06:00) America/Mexico City', value: 'America/Mexico_City' },
  // { label: '(UTC-06:00) America/Merida', value: 'America/Merida' },
  { label: '(UTC-05:00) America/Cancun', value: 'America/Cancun' },
  /* ===== US ===== */
  { label: '(UTC-07:00) America/San Francisco', value: 'America/San_Francisco' },
  // { label: '(UTC-07:00) America/Los Angeles', value: 'America/Los_Angeles' },
  { label: '(UTC-05:00) America/Chicago', value: 'America/Chicago' },
  { label: '(UTC-04:00) America/New York', value: 'America/New_York' },
];
