import AzulEmptyState from 'components/simpleComponents/AzulEmptyState';
import AzulLoadingState from 'components/simpleComponents/AzulLoadingState';
import { FC, Fragment, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import groupsEmptyState from '../../assets/emptyStates/groups_emptyState.svg';
import { BlueAddBtn } from '../../components/blueAddBtn/BlueAddBtn';
import GroupCard from '../../components/groupCard/GroupCard';
import SectionHeader from '../../components/sectionHeader/SectionHeader';
import AzulContainer from '../../components/simpleComponents/AzulContainer';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { readAllGroups } from '../../store/features/groupSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from './Groups.module.scss';

const Groups: FC = () => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.group.groups);
  const isGroupListLoading = useAppSelector((state) => state.group.loading);

  // Fetching the list of groups
  useEffect(() => {
    dispatch(readAllGroups());
  }, [dispatch]);

  return (
    <Fragment>
      <AzulContainer>
        {groups.length ? (
          <Fragment>
            <SectionHeader name='Groups' />

            <Box display='flex' justifyContent='space-between'>
              <Typography className={styles.subHeader}>
                Target your audience effectively when preparing any communication.
              </Typography>

              <BlueAddBtn actionLabel='Create New Group' nameInput='Group Name' />
            </Box>

            {isGroupListLoading && (
              <AzulLoadingState
                height='60vh'
                subtitle='We are loading the groups list, this may take a few seconds.'
                title='Please wait. Loading the Groups List'
              />
            )}
            {!isGroupListLoading && (
              <Grid2 columnSpacing={3} container disableEqualOverflow py='56px' rowSpacing={3}>
                {groups.map(({ id, name, totalMembers }) => (
                  <Grid2 key={name} xs='auto'>
                    <GroupCard groupId={id} groupName={name} totalMembers={totalMembers} />
                  </Grid2>
                ))}
              </Grid2>
            )}
          </Fragment>
        ) : (
          <AzulEmptyState
            customButton={
              <Box sx={{ margin: '1.5rem 0' }}>
                <BlueAddBtn actionLabel='Create New Group' nameInput='Group Name' />
              </Box>
            }
            height='80vh'
            imgAriaLabel='No results found.'
            imgHeight='256px'
            imgSrc={groupsEmptyState}
            imgWidth='256px'
            subTitle='Start creating to tailor communication for your audience.'
            title='No groups formed yet.'
          />
        )}
      </AzulContainer>

      <SimpleSnackbar />
    </Fragment>
  );
};

export default Groups;
