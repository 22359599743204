import { Box, ThemeProvider, Typography } from '@mui/material';
import PageUnderWork from '../../assets/PageUnderWork.svg';
import { paragraphBaseline, primaryBlue } from '../../styles/partials/variables';
import SectionHeader from '../sectionHeader/SectionHeader';
import { azulDesign } from '../simpleComponents/AzulDesignTheme';

type UnderWorkProps = {
  pageName?: string;
};

const UnderWork = ({ pageName }: UnderWorkProps) => (
  <ThemeProvider theme={azulDesign}>
    <SectionHeader />

    <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center' marginTop='2rem'>
      <Typography
        sx={{
          color: primaryBlue,
          fontFamily: 'Rubik',
          fontWeight: '600',
          fontSize: '1.5rem',
          fontStyle: 'normal',
          lineHeight: '2rem',
        }}
      >
        {pageName || 'Page'} Under Construction
      </Typography>
      <Typography
        maxWidth='45%'
        sx={{
          color: paragraphBaseline,
          fontFamily: 'Rubik',
          fontWeight: '400',
          fontSize: '1rem',
          fontStyle: 'normal',
          lineHeight: '1.5rem',
          letterSpacing: '0.002em',
          marginBottom: '2.25rem',
          marginTop: '0.5rem',
          textAlign: 'center',
        }}
      >
        We expect the page to be fully functional soon. In the meantime, feel free to explore all the features.
      </Typography>

      <img alt='Page Under Construction' src={PageUnderWork} />
    </Box>
  </ThemeProvider>
);

export default UnderWork;
