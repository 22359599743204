/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { path } from 'lodash/fp';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Credentials } from '../type/credentials';
import { currentVersion } from '../utils/constants/version';

const sentryStatus = {
  init: false,
};

const environments = ['QA', 'DEV', 'PROD'];

export const init = () => {
  if (process.env.REACT_APP_SENTRY_DSN && environments.includes(process.env.REACT_APP_ENV as string)) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENV,
      release: currentVersion,
      ignoreErrors: [
        'Network Error',
        'Network request failed',
        'Failed to fetch',
        'NetworkError',
        /401/,
        'Request aborted',
        'ResizeObserver loop limit exceeded',
      ],
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.05,
      beforeSend(event) {
        const status = Number(path('extra.response.status', event));

        // If is a credentials problems while logging in then don't send an event
        if (status === 401) {
          return null;
        }

        return event;
      },
      beforeBreadcrumb(breadcrumb: any, hint: any) {
        const customBreadcrumb = breadcrumb;
        if (customBreadcrumb.category === 'ui.click') {
          const { target } = hint.event;
          if (target.ariaLabel) {
            customBreadcrumb.message = target.ariaLabel;
          }
        }
        return customBreadcrumb;
      },
    });
    sentryStatus.init = true;
  }
};

const KNOWN_HTTP_ERROR = [409, 401];

export const api = (error: AxiosError, credentials?: Credentials, extraInfo = {}) => {
  if (!sentryStatus.init) return;

  const url: string | undefined = path(['config', 'url'], error);
  const method = path(['config', 'method'], error);
  const status = Number(path(['response', 'status'], error));
  const apiMessage =
    path(['response', 'data', 'errorDesc'], error) ||
    path(['response', 'statusText'], error) ||
    path(['message'], error) ||
    path(['name'], error);

  let uri = '';
  if (url) uri = url.replace(path(['config', 'baseURL'], error) as string, '');

  const message = `[API] [${method}] ${status} ${uri} - ${apiMessage}`;

  let knownError = 'false';

  // Progress of bookmark
  if (KNOWN_HTTP_ERROR.includes(status)) {
    knownError = 'true';
  }

  const response = error.response || '';
  const request = error.config;

  Sentry.withScope((scope: any) => {
    if (credentials?.user.id) {
      scope.setUser({ email: credentials.user.email, id: credentials.user.id, name: credentials.user.name });
    }

    if (status) {
      scope.setTag('statusCode', status);
    }

    scope.setTag('known-error', knownError);
    Sentry.captureEvent({
      message,
      logger: 'api',
      extra: {
        error,
        extraInfo,
        response,
        request,
      },
    });
  });
};

export enum ERROR_TYPES {
  apiError = 'API',
}
