import { useState, FC, ChangeEvent, MouseEvent, useEffect } from 'react';
import { PublishingStatus } from 'utils/constants/publishingStatus';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Icon,
  ThemeProvider,
  Box,
  TablePagination,
} from '@mui/material';
import { ReactComponent as BlueStatus } from '../../../assets/EllipseBlue.svg';
import { ReactComponent as GreenStatus } from '../../../assets/EllipseGreen.svg';
import { ReactComponent as YellowStatus } from '../../../assets/EllipseYellow.svg';
import { primaryBlue } from '../../../styles/partials/variables';
import { Announcement } from '../../../type/announcement';
import MoreSettingsBtn from '../../moreSettingsBtn/MoreSettingsBtn';
import { azulDesign } from '../../simpleComponents/AzulDesignTheme';
import AzulPagination from '../../simpleComponents/AzulPagination';

type StyledAnnouncementTableProps = {
  audienceType: 'groups' | 'individuals';
  count: number; // The lenght of the data, equivalent to the number of rows
  data: Announcement[]; // The fetched announcements' data for the current group
};

// The table receives the data to display, and the count is the number of rows, or data.length
export const StyledAnnouncementTable: FC<StyledAnnouncementTableProps> = ({
  audienceType,
  count,
  data,
}: StyledAnnouncementTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  // Changes the layout when changing page number
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null | ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  // Calculates the number of rows on each page
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Goes to the first page when data changes
  useEffect(() => {
    setPage(0);
  }, [data]);

  function handleStatusColor(announcement: Announcement): import('react').ReactNode {
    if (announcement.status === PublishingStatus.Scheduled) {
      return <YellowStatus />;
    }
    if (announcement.status === PublishingStatus.Published) {
      return <GreenStatus />;
    }
    return <BlueStatus />;
  }

  return (
    <ThemeProvider theme={azulDesign}>
      {/* The main table container */}
      <TableContainer component={Paper}>
        <Table aria-label='custom pagination table'>
          {/* The table's first row with the column titles */}
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ANNOUNCEMENT NAME</TableCell>
              <TableCell>AUDIENCE</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>DURATION</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {/* Where the searched announcements data information is actually displayed */}
          <TableBody>
            {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(
              (announcement) => {
                const annAudience: string[] = [];
                announcement.AudienceAnnouncement.forEach(({ group, fullName }) => {
                  if (group) {
                    if (!annAudience.includes(group.name)) {
                      annAudience.push(group.name);
                    }
                  } else if (!annAudience.includes(fullName)) {
                    annAudience.push(fullName);
                  }
                });
                return (
                  <TableRow key={announcement.id}>
                    <TableCell align='center' component='th' scope='row' style={{ width: 64 }}>
                      <Icon
                        baseClassName='material-icons-round'
                        sx={{ color: primaryBlue, fontFamily: 'Material Icons Round !important' }}
                      >
                        {announcement.icon_reference}
                      </Icon>
                    </TableCell>
                    <TableCell>{announcement.name}</TableCell>
                    <TableCell sx={{ minWidth: '13rem' }}>{annAudience.join(', ')}</TableCell>
                    <TableCell sx={{ minWidth: '5.5rem' }}>{announcement.date}</TableCell>
                    <TableCell>
                      {announcement.duration &&
                        `${announcement.duration} ${announcement.duration === 1 ? 'day' : 'days'}`}
                    </TableCell>
                    <TableCell sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '5px' }}>
                      {handleStatusColor(announcement)}
                      <Typography
                        component='span'
                        sx={{ marginLeft: '.5rem' }}
                      >{`${announcement.status[0].toUpperCase()}${announcement.status.slice(1)}`}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <MoreSettingsBtn
                        audienceType={audienceType}
                        blueStyle
                        crudId={announcement.id}
                        label='Announcement'
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
            {count === 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell align='center' colSpan={7}>
                  No results found
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* The above table's pagination with a custom styling */}
      <Box alignItems='center' display='grid' gridTemplateColumns='2fr 1fr' justifyItems='right' mt='24px' width='100%'>
        <AzulPagination
          currentPage={page}
          numberOfPages={Math.ceil(count / rowsPerPage)}
          onPageChange={handleChangePage}
        />
        <TablePagination
          ActionsComponent='noindex'
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: false,
            IconComponent: KeyboardArrowDown,
          }}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: count }]}
          sx={{ width: 1100 }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </ThemeProvider>
  );
};
