import { AxiosResponse } from 'axios';
import { SessionStatus } from 'utils/constants/sessionStatus';
import {
  clearSavedCredentials,
  confirmSession,
  getSavedCredentials,
  logOut,
  setCredentials,
  setHasError,
  setIsLoading,
  setSavedCredentials,
  updateCurrentUser,
  updateIsAuthenticated,
  updateSessionStatus,
} from '.';
import { authService } from '../../services/authService';
import { userService } from '../../services/usersService';
import { Credentials } from '../../type/credentials';
import { setStatusCode } from '../errors';
import { AppThunk } from '../store';
import { selectSessionCredentials } from './selectors';

export const updateCredentials =
  (credentials: Credentials): AppThunk =>
  async (dispatch) => {
    setSavedCredentials(credentials);
    dispatch(setCredentials(credentials));
  };

export const closeSession = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await authService.logout();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    dispatch(setStatusCode(error.response as AxiosResponse));
  } finally {
    clearSavedCredentials();
    dispatch(logOut());
    dispatch(setIsLoading(false));
  }
};

const populateUserSession = (): AppThunk => async (dispatch, getState) => {
  const credentials = selectSessionCredentials(getState());
  if (!credentials) {
    throw new Error('No Credentials found');
  }
  try {
    const { data } = await userService.getById(credentials.user.id);
    dispatch(updateCurrentUser(data));
    dispatch(updateIsAuthenticated(true));
    dispatch(updateSessionStatus(SessionStatus.fulfilled));
  } catch (error) {
    dispatch(closeSession());
    console.error(error);
  }
};

export const initSession = (): AppThunk => async (dispatch) => {
  const credentials = getSavedCredentials();

  if (!credentials) {
    dispatch(confirmSession());
    dispatch(updateSessionStatus(SessionStatus.logOut));
    return;
  }

  try {
    dispatch(setIsLoading(true));
    dispatch(updateCredentials(credentials));
    await dispatch(populateUserSession());
  } catch (error) {
    clearSavedCredentials();
    dispatch(logOut());
    console.error(error);
  } finally {
    dispatch(confirmSession());
    dispatch(setIsLoading(false));
  }
};

export const logInWithExchange =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setHasError(false));
      const { data } = await authService.exchange(token);
      setSavedCredentials(data);
    } catch (error) {
      dispatch(setHasError(true));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
    dispatch(initSession());
  };
