import AzulEmptyState from 'components/simpleComponents/AzulEmptyState';
import AzulLoadingState from 'components/simpleComponents/AzulLoadingState';
import { useState, useEffect, FC, Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import adminListEmptyState from '../../assets/emptyStates/adminList_emptyState.svg';
import { BlueAddBtn } from '../../components/blueAddBtn/BlueAddBtn';
import { SearchBar } from '../../components/searchBar/SearchBar';
import SectionHeader from '../../components/sectionHeader/SectionHeader';
import AzulContainer from '../../components/simpleComponents/AzulContainer';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { StyledAdminsTable } from '../../components/tableList/adminsTable/StyledAdminsTable';
import { readAllAdmins } from '../../store/features/adminSlice';
import { User } from '../../type/user';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from '../editGroup/EditGroup.module.scss';

const AdminList: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const dispatch = useAppDispatch();
  const admins = useAppSelector((state) => state.admin.admins);
  const isAdminListLoading = useAppSelector((state) => state.admin.loading);

  // Filters the admins array inside the table, user by user. Returns the admins that match the search value
  const tableSearch = (Users: Array<User>) =>
    Users.filter(
      ({ firstName, lastName, email }: User) =>
        firstName.toLowerCase().includes(searchValue) ||
        lastName.toLowerCase().includes(searchValue) ||
        email.toLowerCase().includes(searchValue),
    );

  const adminsToList = tableSearch(admins);

  // Updates the admins array (to be passed into the table search) every time a new input value is entered into the search bar
  useEffect(() => {
    dispatch(readAllAdmins());
  }, [searchValue, dispatch]);

  return (
    <Fragment>
      <AzulContainer>
        {admins.length ? (
          <Fragment>
            <SectionHeader name='Admin List' />

            <Box display='flex' justifyContent='space-between' marginBottom='3.5rem'>
              <Typography className={styles.subHeader}>
                {`Easily assign admin roles by typing in members' names.`}
              </Typography>

              {/* This button allows to add admins by opening a modal */}
              <BlueAddBtn actionLabel='Add New Admin' />
            </Box>

            {/* The callback allows us to acces the search bar's input inner query/value, to set it out here for the other components */}
            <SearchBar placeholderTxt='Search by name or email' searchQuery={(value) => setSearchValue(value)} />

            {isAdminListLoading && (
              <AzulLoadingState
                height='50vh'
                subtitle='We are loading the administators list, this may take a few seconds.'
                title='Please wait. Loading the Administrators List'
              />
            )}
            {!isAdminListLoading && (
              /* The data prop searches the table with the query/value entered in the search bar component */
              <StyledAdminsTable count={adminsToList.length} data={adminsToList} />
            )}
          </Fragment>
        ) : (
          <AzulEmptyState
            customButton={
              <Box sx={{ margin: '1.5rem 0' }}>
                <BlueAddBtn actionLabel='Add New Admin' />
              </Box>
            }
            height='80vh'
            imgAriaLabel='No results found.'
            imgHeight='256px'
            imgSrc={adminListEmptyState}
            imgWidth='256px'
            subTitle='Start assigning admin roles by typing member names.'
            title='No administrators added yet.'
          />
        )}
      </AzulContainer>

      <SimpleSnackbar />
    </Fragment>
  );
};

export default AdminList;
