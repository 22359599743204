import { FC } from 'react';
import AzulContainer from '../../components/simpleComponents/AzulContainer';
import UnderWork from '../../components/underWork/UnderWork';

const Dashboard: FC = () => (
  <AzulContainer>
    <UnderWork pageName='Dashboard' />
  </AzulContainer>
);

export default Dashboard;
