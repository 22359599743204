import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import errorsReducer from './errors';
import {
  announcementReducer,
  backgroundReducer,
  counterReducer,
  groupReducer,
  menuReducer,
  memberReducer,
  userReducer,
  adminReducer,
} from './features';
import sessionReducer from './session';

export const store = configureStore({
  reducer: {
    announcement: announcementReducer,
    background: backgroundReducer,
    counter: counterReducer,
    menu: menuReducer,
    group: groupReducer,
    member: memberReducer,
    user: userReducer,
    admin: adminReducer,
    errors: errorsReducer,
    session: sessionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
