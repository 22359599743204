import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useEffect, useRef, Fragment } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

import deleteImageButton from '../../assets/deleteImgBtn.png';
import { KonvaBox } from '../../type/konva';
import { ImgProps } from './DragAndResizeImages';

type DraggableImageProps = {
  draggableImgProps: ImgProps;
  image: string;
  isSelected: boolean;
  onSelect: () => void;
  onDelete: (e: KonvaEventObject<MouseEvent>) => void;
  onChange: (e: ImgProps) => void;
};

const DraggableImage = ({
  draggableImgProps,
  image,
  isSelected,
  onChange,
  onDelete,
  onSelect,
}: DraggableImageProps) => {
  const imageRef = useRef<Konva.Image>(null);
  const transformRef = useRef<Konva.Transformer>(null);
  const deleteRef = useRef<Konva.Image>(null);
  const [importedImage] = useImage(image);
  const [deleteImportedImage] = useImage(deleteImageButton);

  const imageRefWitdh = imageRef?.current?.width();

  useEffect(() => {
    if (isSelected && transformRef.current && imageRef.current) {
      transformRef.current.setNode(imageRef.current);
      const transformLayer = transformRef.current.getLayer();
      if (transformLayer) {
        transformLayer.batchDraw();
      }
    }
  }, [isSelected]);

  const handleBoundBoxFunction = (oldBox: KonvaBox, newBox: KonvaBox) => {
    if (newBox.width < 5 || newBox.height < 5) {
      return oldBox;
    }
    return newBox;
  };

  const handleOnDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    onChange({
      ...draggableImgProps,
      x: e.target.x(),
      y: e.target.y(),
    });
  };

  const handleOnTransformEnd = () => {
    const node = imageRef.current;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);
      onChange({
        ...draggableImgProps,
        x: node.x(),
        y: node.y(),
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(node.height() * scaleY),
      });
    }
  };

  return (
    <Fragment>
      <Image
        ref={imageRef}
        onClick={onSelect}
        {...draggableImgProps}
        draggable
        image={importedImage}
        onDragEnd={handleOnDragEnd}
        onTransformEnd={handleOnTransformEnd}
      />

      {isSelected && (
        <Transformer boundBoxFunc={handleBoundBoxFunction} ref={transformRef} useSingleNodeRotation={false}>
          <Image
            height={24}
            image={deleteImportedImage}
            ref={deleteRef || null}
            width={24}
            x={imageRefWitdh ? imageRefWitdh - 30 : 0} // TO DO: Add value to use State and update through use Effect (positioning bug when resizing)
            y={5}
            onClick={onDelete}
          />
        </Transformer>
      )}
    </Fragment>
  );
};

export default DraggableImage;
