import { Card, CardContent, CardHeader } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)(() => ({
  boxShadow: '0px 5px 10px rgba(29, 63, 156, 0.2)',
  border: '1px solid #ECECED',
  borderRadius: '8px',
  height: '143px',
  margin: 0,
  textAlign: 'center',
  width: '180px',
}));

export const StyledCardHeader = styled(CardHeader)(() => ({
  padding: '.5rem .75rem 0 .75rem',
}));

export const StyledCardContent = styled(CardContent)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '63px',
  margin: '0 auto 0 auto',
  padding: '0 .5rem 0 .5rem',
}));
