/* eslint-disable camelcase */
import { ServiceOptions } from 'services/baseService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { groupService } from '../../services/groupsService';
import { Group, GroupMember, MemberState } from '../../type/group';
import { UsersIdArray } from '../../type/user';

interface GroupMembers extends Group {
  userGroup: GroupMember[];
}

const initialState: MemberState = {
  loading: false,
  members: [],
};

export const addGroupMembers = createAsyncThunk<GroupMember[], { groupId: number; members: UsersIdArray[] }>(
  'group_member/addGroupMembers',
  async (
    req: { groupId: number; members: { memberEmail: string; memberName: string; memberUid: string }[] },
    { rejectWithValue },
  ) => {
    try {
      const { groupId, members } = req;
      await groupService.patch(groupId, { members });
      const options: ServiceOptions = {
        include: ['GroupMembers'],
      };
      const { data } = await groupService.getById(groupId, options);
      return data.userGroup; // returns the array of all the users that are part of the group
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readMembersByGroupId = createAsyncThunk<GroupMembers, number>(
  'group_member/readMembersByGroupId',
  async (groupId: number, { rejectWithValue }) => {
    try {
      const options: ServiceOptions = {
        include: ['GroupMembers'],
      };
      const { data } = await groupService.getById(groupId, options);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeGroupMemberByIds = createAsyncThunk<string, { groupId: number; memberEmail: string }>(
  'group_member/removeGroupMemberByIds',
  async (req: { groupId: number; memberEmail: string }, { rejectWithValue }) => {
    try {
      const { groupId, memberEmail } = req;
      await groupService.deleteGroupMemberByIds(groupId, memberEmail);
      return memberEmail;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const memberSlice = createSlice({
  name: 'group_member',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    // ========================== ADD MEMBERS ==========================
    // When our request is pending:
    builder.addCase(addGroupMembers.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(addGroupMembers.fulfilled, (state, action) => {
      state.members = action.payload;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(addGroupMembers.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ GROUP MEMBERS ==========================
    // When our request is pending:
    builder.addCase(readMembersByGroupId.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readMembersByGroupId.fulfilled, (state, action) => {
      state.members = action.payload.userGroup;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readMembersByGroupId.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== DELETE MEMBERS ==========================
    // When our request is pending:
    builder.addCase(removeGroupMemberByIds.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(removeGroupMemberByIds.fulfilled, (state, action) => {
      // TO DO: ask backend to set desrtuctured variables to camel case, added eslint comment on top since it complains because of this
      state.members = state.members.filter(({ member_email }) => member_email !== action.payload);
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(removeGroupMemberByIds.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default memberSlice.reducer;
