import { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import backgroundsEmptyState from '../../assets/emptyStates/backgrounds_emptyState.svg';
import CreateBackgroundModal from '../../components/createBackgroundModal/CreateBackgroundModal';
import SectionHeader from '../../components/sectionHeader/SectionHeader';
import AzulContainer from '../../components/simpleComponents/AzulContainer';
import AzulEmptyState from '../../components/simpleComponents/AzulEmptyState';
import AzulLoadingState from '../../components/simpleComponents/AzulLoadingState';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { StyledBackgroundsTable } from '../../components/tableList/backgroundsTable/StyledBackgroundsTable';
import { readAllBackgrounds } from '../../store/features/backgroundSlice';
import { primaryBlue } from '../../styles/partials/variables';
import { AudienceType } from '../../utils/constants/audienceType';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from '../announcements/Announcements.module.scss';

const Backgrounds = () => {
  const dispatch = useAppDispatch();
  const { hash: urlHash } = useLocation();
  const tabNames = [AudienceType.Group, AudienceType.Individual];
  const [tab, setTab] = useState<string>(urlHash.replace('#', '') || AudienceType.group);
  const groupBackgrounds = useAppSelector((state) => state.background.groupBackgrounds);
  const individualBackgrounds = useAppSelector((state) => state.background.individualBackgrounds);
  const isBackgroundListLoading = useAppSelector((state) => state.background.loading);
  const isBackgroundListEmpty =
    (tab === 'individual' && !individualBackgrounds.length) || (tab === 'group' && !groupBackgrounds.length);

  // Fetching the list of groups
  useEffect(() => {
    dispatch(readAllBackgrounds());
  }, [dispatch]);

  // Handler to display the chosen tab
  const handleTabChange = (event: SyntheticEvent, newValue: 'groups' | 'individuals') => {
    setTab(newValue);
  };

  return (
    <Fragment>
      <AzulContainer>
        <SectionHeader name='Background' />

        <Box display='flex' justifyContent='space-between'>
          <Typography className={styles.subHeader}>
            Use a static background (wallpaper) to show off significant occasions like birthdays, anniversaries, and
            bussiness milestones.
          </Typography>

          {/* TODO: Check and standardize the way tab names are spelled (singular or plural) */}
          {!isBackgroundListEmpty && <CreateBackgroundModal audienceType={`${tab}s`} />}
        </Box>

        <TabContext value={tab}>
          <TabList
            TabIndicatorProps={{ style: { backgroundColor: primaryBlue } }}
            aria-label='background tabs'
            sx={{ marginTop: '2rem' }}
            onChange={handleTabChange}
          >
            {tabNames.map((tabName) => (
              <Tab
                disableRipple
                key={`${tabName}-${uuidv4()}`}
                label={tabName}
                sx={{
                  '&.Mui-selected': {
                    color: primaryBlue,
                    fontWeight: 500,
                  },
                  fontFamily: 'Rubik, sans serif',
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: '1.5rem',
                  textTransform: 'none',
                }}
                value={tabName.toLowerCase()}
              />
            ))}
          </TabList>

          <TabPanel sx={{ padding: '2rem 0 0 0' }} value={AudienceType.group}>
            {isBackgroundListLoading && (
              <AzulLoadingState
                height='50vh'
                subtitle='We are loading the group backgrounds list, this may take a few seconds.'
                title='Please wait. Loading the Backgrounds List'
              />
            )}
            {!isBackgroundListLoading &&
              (groupBackgrounds.length ? (
                <StyledBackgroundsTable audienceType='groups' count={groupBackgrounds.length} data={groupBackgrounds} />
              ) : (
                <AzulEmptyState
                  customButton={
                    <Box sx={{ margin: '1.5rem 0' }}>
                      <CreateBackgroundModal audienceType={`${tab}s`} />
                    </Box>
                  }
                  imgAriaLabel='No results found.'
                  imgHeight='256px'
                  imgSrc={backgroundsEmptyState}
                  imgWidth='256px'
                  subTitle='Start adding wallpapers for key celebrations and milestones.'
                  title='No group backgrounds generated so far.'
                />
              ))}
          </TabPanel>
          <TabPanel sx={{ padding: '2rem 0 0 0' }} value={AudienceType.individual}>
            {isBackgroundListLoading && (
              <AzulLoadingState
                height='50vh'
                subtitle='We are loading the individual backgrounds list, this may take a few seconds.'
                title='Please wait. Loading the Backgrounds List'
              />
            )}
            {!isBackgroundListLoading &&
              (individualBackgrounds.length ? (
                <StyledBackgroundsTable
                  audienceType='individuals'
                  count={individualBackgrounds.length}
                  data={individualBackgrounds}
                />
              ) : (
                <AzulEmptyState
                  customButton={
                    <Box sx={{ margin: '1.5rem 0' }}>
                      <CreateBackgroundModal audienceType={`${tab}s`} buttonLabel='Create New Background' />
                    </Box>
                  }
                  imgAriaLabel='No results found.'
                  imgHeight='256px'
                  imgSrc={backgroundsEmptyState}
                  imgWidth='256px'
                  subTitle='Start adding wallpapers for key celebrations and milestones.'
                  title='No individual backgrounds generated so far.'
                />
              ))}
          </TabPanel>
        </TabContext>
      </AzulContainer>

      <SimpleSnackbar />
    </Fragment>
  );
};

export default Backgrounds;
