import { Dayjs } from 'dayjs';
import { FC, ReactNode } from 'react';
import { TimeValidationError } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { PickerChangeHandler } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue';
import {
  destructiveRed2,
  disabledGray1,
  disabledGray2,
  paragraphBaseline,
  primaryBlue,
} from '../../styles/partials/variables';

type AzulTimePickerProps = {
  disabled?: boolean;
  format?: string;
  helperText?: string;
  label?: ReactNode;
  value?: Dayjs | null;
  width?: string;
  onChange?: PickerChangeHandler<Dayjs | null, TimeValidationError>;
};

const AzulTimePicker: FC<AzulTimePickerProps> = ({
  disabled,
  format,
  helperText,
  label,
  value,
  width,
  onChange,
}: AzulTimePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TimePicker
      disableIgnoringDatePartForTimeValidation
      disabled={disabled}
      format={format}
      label={label}
      slotProps={{
        textField: {
          helperText,
        },
      }}
      sx={{
        fontFamily: 'Rubik',
        width: { width },
        height: '3rem',
        '& .MuiInputLabel-root': {
          color: paragraphBaseline,
          fontFamily: 'Rubik',
          top: '-0.225rem',
          '&.Mui-focused': {
            color: primaryBlue,
          },
          '&.Mui-error': {
            color: destructiveRed2,
          },
          '&.Mui-disabled': {
            color: disabledGray2,
          },
        },
        '& .MuiOutlinedInput-input': {
          fontSize: '1rem',
          height: '1.5rem',
          letterSpacing: '0.002em',
          padding: '.75rem',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: paragraphBaseline,
            borderRadius: '8px',
          },
          '&.Mui-focused fieldset': {
            borderColor: primaryBlue,
          },
          '&.Mui-error fieldset': {
            borderColor: destructiveRed2,
          },
          '&.Mui-disabled fieldset': {
            backgroundColor: disabledGray1,
            zIndex: -1,
          },
        },
        '& .MuiFormHelperText-root': {
          color: paragraphBaseline,
          fontFamily: 'Rubik',
          '&.Mui-error': {
            color: destructiveRed2,
          },
          '&.Mui-disabled': {
            color: disabledGray2,
          },
        },
      }}
      value={value}
      onChange={onChange}
    />
  </LocalizationProvider>
);

export default AzulTimePicker;
