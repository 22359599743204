import { Group } from 'type/group';
import { BaseService, ServiceOptions, prepareParams } from './baseService';

class GroupService extends BaseService<Group> {
  controllerName = 'group';

  async deleteGroupMemberByIds(groupId: number, userEmail: string, options?: ServiceOptions) {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.delete(`${this.controllerName}/?group_id=${groupId}&member_email=${userEmail}`, {
      params,
    });
    return data;
  }
}

export const groupService = new GroupService();
