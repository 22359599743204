import { AudienceAnnouncement } from 'type/announcement';
import { AudienceType } from '../utils/constants/audienceType';
import { BaseService } from './baseService';

class AudienceAnnouncementService extends BaseService<AudienceAnnouncement> {
  controllerName = 'audienceAnnouncement';

  async createNewAudienceAnnouncement(announcementId: number, value: number | string) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const audienceType = typeof value === 'string' ? AudienceType.individual : AudienceType.group;
    const bodyData =
      audienceType === AudienceType.individual ? { announcementId, value } : { announcementId, groupId: value };
    const res = await this.api.post(`${this.controllerName}/${audienceType}`, bodyData);
    return res.data.data;
  }
}

export const audienceAnnouncementService = new AudienceAnnouncementService();
