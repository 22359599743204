import { Checkbox } from '@mui/material';
import { ReactComponent as CheckboxIcon } from '../../assets/Checkbox.svg';
import { ReactComponent as CheckboxOutlineIcon } from '../../assets/CheckboxOutline.svg';

type AzulCheckboxProps = { checked: boolean };

const AzulCheckbox = ({ checked }: AzulCheckboxProps) => (
  <Checkbox
    checked={checked}
    checkedIcon={<CheckboxIcon />}
    disableRipple
    icon={<CheckboxOutlineIcon />}
    sx={{ padding: 0 }}
  />
);

export default AzulCheckbox;
