import { Box, ThemeProvider, Typography } from '@mui/material';
import { azulDesign } from './AzulDesignTheme';
import AzulSpinner from './AzulSpinner/AzulSpinner';

type AzulLoadingStateProps = {
  height?: string;
  subtitle?: string;
  title?: string;
};

const AzulLoadingState = ({
  height = '100vh',
  subtitle = 'We are loading the data, this may take a few seconds.',
  title = 'Please wait. Loading',
}: AzulLoadingStateProps) => (
  <ThemeProvider theme={azulDesign}>
    <Box alignItems='center' display='flex' flexDirection='column' height={height} justifyContent='center'>
      <AzulSpinner />
      <Typography sx={{ textTransform: 'unset' }} variant='loginHeader'>
        {title}
      </Typography>
      <Typography variant='paragraph'>{subtitle}</Typography>
    </Box>
  </ThemeProvider>
);

export default AzulLoadingState;
