import { MouseEvent } from 'react';
import { black, disabledGray2 } from 'styles/partials/variables';
import { TextToolSetOptions } from 'utils/constants/canvas';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

interface TextToolSetProps {
  textColor: string;
  textTool?: TextToolSetOptions;
  onChangeTextTool: (event: MouseEvent<HTMLElement>, value: TextToolSetOptions) => void;
  onClickTextColorTool: (event: MouseEvent<HTMLElement>) => void;
}

export const TextToolSet = ({ textColor, textTool, onChangeTextTool, onClickTextColorTool }: TextToolSetProps) => {
  const handleClickToggleGroup = (event: MouseEvent<HTMLDivElement>) => event.stopPropagation();

  return (
    <Paper elevation={24} id='text-tool-set' sx={{ position: 'absolute', left: '4px' }}>
      <ToggleButtonGroup
        exclusive
        orientation='vertical'
        sx={{
          height: 184,
          width: 40,
        }}
        value={textTool}
        onChange={onChangeTextTool}
        onClick={handleClickToggleGroup}
      >
        <ToggleButton
          aria-label='text color'
          className='textToolSetButton'
          value={TextToolSetOptions.TextColor}
          onClick={onClickTextColorTool}
        >
          <Box display='flex' flexDirection='column'>
            <Typography color={black} component='span' variant='textColorTool'>
              A
            </Typography>
            <Box bgcolor={textColor} height='4px' width='33px' />
          </Box>
        </ToggleButton>
        <ToggleButton aria-label='text size' className='textToolSetButton' disabled value={TextToolSetOptions.TextSize}>
          <Box alignItems='center' display='flex' justifyContent='center'>
            <Typography color={disabledGray2} component='span' variant='textSizeTool'>
              24
            </Typography>
            <UnfoldMoreIcon sx={{ fontSize: '1rem' }} />
          </Box>
        </ToggleButton>
        <ToggleButton aria-label='bold' className='textToolSetButton' disabled value={TextToolSetOptions.Bold}>
          <ViewModuleIcon />
        </ToggleButton>
        <ToggleButton
          aria-label='alignment'
          className='textToolSetButton'
          disabled
          value={TextToolSetOptions.Alignment}
        >
          <FormatAlignLeftIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Paper>
  );
};
