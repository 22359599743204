import { FC, Fragment, useContext, useState } from 'react';
import { ReactComponent as DeleteSVG } from '../../assets/Delete.svg';
import { removeAdminById } from '../../store/features/adminSlice';
import { removeAnnouncementById } from '../../store/features/announcementSlice';
import { removeBackgroundById } from '../../store/features/backgroundSlice';
import snackContext from '../../store/features/contextSnackbar';
import { removeGroupById } from '../../store/features/groupSlice';
import { removeGroupMemberByIds } from '../../store/features/memberSlice';
import { useAppDispatch } from '../../utils/hooks/storeHooks';
import styles from '../../views/groups/Groups.module.scss';
import buttonStyles from '../blueAddBtn/BlueAddBtn.module.scss';
import { BlueAddButton } from '../blueAddBtn/StyledBtn';
import { StyledMenuItem } from '../moreSettingsBtn/StyledComponents';
import AzulModal from '../simpleComponents/Modal';

type DeleteModalProps = {
  actionLabel?: string; // The label for the confirm delete button
  cancelActionLabel?: string; // The label for the cancel delete button
  crudId?: number; // The ID that will be used in the delete service
  endIcon?: boolean; // Determines where and how will the Delete icon render in the button that launches the modal
  label: string; // The general label for the modal, so you know what are you deleting
  name?: string; // The name of the concrete element to delete, used for the snackbar
  userEmail?: string; // The email of the user involved in the delete service
};

const DeleteModal: FC<DeleteModalProps> = ({
  actionLabel = 'Yes, Delete',
  cancelActionLabel = 'Cancel',
  crudId = 0,
  endIcon = false,
  label,
  name,
  userEmail,
}: DeleteModalProps) => {
  const dispatch = useAppDispatch();
  const { setSnackPack } = useContext(snackContext);
  /* States */
  const [open, setOpen] = useState<boolean>(false);

  /* Handlers for opening and closing the DeleteModal */
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /* Handler for deleting operation */
  const handleDelete = async (groupId: number, memberEmail?: string) => {
    let isSnackSuccessful: boolean;
    let snackMsg: string;
    switch (label.toLowerCase()) {
      case 'admin':
        isSnackSuccessful = (await dispatch(removeAdminById(groupId))).meta.requestStatus === 'fulfilled';
        snackMsg = `The admin has been deleted`;
        break;
      case 'announcement':
        isSnackSuccessful = (await dispatch(removeAnnouncementById(groupId))).meta.requestStatus === 'fulfilled';
        snackMsg = `The announcement has been deleted`;
        break;
      case 'background':
        isSnackSuccessful = (await dispatch(removeBackgroundById(groupId))).meta.requestStatus === 'fulfilled';
        snackMsg = `The background has been deleted`;
        break;
      case 'group':
        isSnackSuccessful = (await dispatch(removeGroupById(groupId))).meta.requestStatus === 'fulfilled';
        snackMsg = `Group ${name} has been deleted`;
        break;
      case 'member':
        if (memberEmail) {
          isSnackSuccessful =
            (await dispatch(removeGroupMemberByIds({ groupId, memberEmail }))).meta.requestStatus === 'fulfilled';
          snackMsg = `The member has been removed succesfully`;
        }
        break;
      default:
        snackMsg = `${label} has been deleted`;
        break;
    }
    handleClose();
    setSnackPack((prev) => [...prev, { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() }]);
  };

  return (
    <AzulModal
      modalActions={
        <Fragment>
          {/* ONLY renders the twin cancel button if cancelActionLabel prop was provided */}
          {cancelActionLabel && (
            <button
              className={`${buttonStyles.actionBtn} ${buttonStyles.cancelAction}`}
              style={{ marginRight: '.5rem' }}
              type='button'
              onClick={handleClose}
            >
              {cancelActionLabel}
            </button>
          )}

          <BlueAddButton
            className={`${buttonStyles.actionBtn} ${buttonStyles.doAction}`}
            disableElevation
            variant='contained'
            onClick={() => {
              handleDelete(crudId, userEmail);
            }}
          >
            {actionLabel}
          </BlueAddButton>
        </Fragment>
      }
      open={open}
      title={`Delete ${label}`}
      titleHelperText={`Are you sure you want to delete this ${label.toLowerCase()} ?`}
      onClose={handleClose}
    >
      {endIcon ? (
        <button className={buttonStyles.deleteMemberBtn} onClick={handleOpen}>
          Delete &nbsp;
          <DeleteSVG style={{ alignSelf: 'center' }} />
        </button>
      ) : (
        <StyledMenuItem TouchRippleProps={{ style: { color: '#C0D1FF' } }} onClick={handleOpen}>
          <DeleteSVG style={{ marginRight: '.75rem' }} />

          <p className={`${styles.P1} ${styles.pointer}`}>Delete {label}</p>
        </StyledMenuItem>
      )}
    </AzulModal>
  );
};

export default DeleteModal;
