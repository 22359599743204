import { RootState } from 'store/store';
import { Credentials } from 'type/credentials';
import { User } from 'type/user';
import { SessionStatus } from 'utils/constants/sessionStatus';

export const selectCurrentUser = (state: RootState): User | undefined => state.session.currentUser;
export const selectErrorMessage = (state: RootState): string => state.session.errorMessage;
export const selectHasError = (state: RootState): boolean => state.session.hasError;
export const selectIsAuth = (state: RootState): boolean => state.session.isAuthenticated;
export const selectIsForgotPasswordSuccessful = (state: RootState): boolean => state.session.isForgotPasswordSuccessful;
export const selectIsRefreshing = (state: RootState): boolean => state.session.isRefreshing;
export const selectIsRegisterSuccessful = (state: RootState): boolean => state.session.isRegisterSuccessful;
export const selectIsSessionConfirmed = (state: RootState): boolean => state.session.isSessionConfirmed;
export const selectIsSessionLoading = (state: RootState): boolean => state.session.isLoading;
export const selectLogInStatus = (state: RootState): SessionStatus => state.session.status;
export const selectSessionCredentials = (state: RootState): Credentials | undefined => state.session.credentials;
