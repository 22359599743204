import { ListItemButton, ListItemText } from '@mui/material';
import ExpandMore from '../../../assets/ExpandMore.svg';
import ExpandLess from '../../../assets/ExpandLess.svg';
import styles from '../SideMainMenu.module.scss';

type Props = {
  onClickFunction: () => void;
  sectionName: string;
  isExpanded: boolean;
};

const ListSection = ({ onClickFunction, sectionName, isExpanded }: Props) => (
  <ListItemButton
    TouchRippleProps={{ style: { color: '#C0D1FF' } }}
    sx={{
      borderRadius: '4px',
      pb: 0,
      pt: 0,
      '&.Mui-focusVisible': {
        backgroundColor: '#F9FBFF',
        outline: '2px solid #b770fe',
        borderRadius: '4px',
      },
      ':hover': {
        backgroundColor: '#ECF1FD',
      },
    }}
    onClick={() => {
      onClickFunction();
    }}
  >
    <ListItemText>
      <strong className={styles.title}>{sectionName}</strong>
    </ListItemText>

    {isExpanded ? <img alt='Expand less.' src={ExpandLess} /> : <img alt='Expand more.' src={ExpandMore} />}
  </ListItemButton>
);

export default ListSection;
