import { BackgroundFile } from 'type/file';
import { BaseService } from './baseService';

class FileService extends BaseService<BackgroundFile> {
  controllerName = 'file';

  async createNewBackgroundFile(fileName: string) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post(
      `${this.controllerName}`,
      JSON.stringify({
        type: 'background',
        fileName,
      }),
    );
    return data;
  }

  async uploadFile(file: File, fileName: string) {
    const { data: newFile } = await this.createNewBackgroundFile(fileName);
    await fetch(newFile.uploadUrl as string, {
      method: 'PUT',
      body: file,
    });
    return newFile;
  }
}

export const fileService = new FileService();
