import { IconButton, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenuItem = styled(MenuItem)(() => ({
  borderRadius: '8px',

  ':focus-visible': {
    backgroundColor: '#ECF1FD',
    borderRadius: '8px',
    outline: '2px solid #b770fe',
  },
  ':hover': {
    backgroundColor: '#ECF1FD',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  color: '#474748',

  ':focus-visible': {
    outline: '2px solid #b770fe',
    borderRadius: '4px',
    color: '#2A5BDF',
  },
  ':hover': {
    backgroundColor: '#ECF1FD',
    color: '#2A5BDF',
  },
}));
