import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { groupService } from '../../services/groupsService';
import { Group, GroupState } from '../../type/group';
import { UsersIdArray } from '../../type/user';

const initialState: GroupState = {
  groups: [],
  loading: false,
};

export const postNewGroup = createAsyncThunk<Group | undefined, { usersIdArr: UsersIdArray[]; name: string }>(
  'group/postNewGroup',
  async (
    req: { usersIdArr: { memberEmail: string; memberName: string; memberUid: string }[]; name: string },
    { rejectWithValue },
  ) => {
    try {
      await groupService.create({ name: req.name, createdBy: 1, members: req.usersIdArr });
      const { data } = await groupService.getAll();

      return data.find((group: Group) => group.name === req.name);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readAllGroups = createAsyncThunk<Group[]>('group/readAllGroups', async (_: void, { rejectWithValue }) => {
  try {
    const { data } = await groupService.getAll();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const readGroup = createAsyncThunk<Group, number>('group/readGroup', async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await groupService.getById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateGroupNameById = createAsyncThunk<Group | undefined, { id: number; newName: string }>(
  'group/updateGroupNameById',
  async (req: { id: number; newName: string }, { rejectWithValue }) => {
    try {
      await groupService.patch(req.id, { name: req.newName });
      const { data } = await groupService.getAll();

      return data.find((group: Group) => group.id === req.id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeGroupById = createAsyncThunk<number, number>(
  'group/removeGroupById',
  async (id: number, { rejectWithValue }) => {
    try {
      await groupService.delete(id);

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  // ========================== ADD GROUP ==========================
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    // When our request is pending:
    builder.addCase(postNewGroup.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(postNewGroup.fulfilled, (state, action) => {
      if (action.payload) {
        state.groups.push(action.payload);
        state.groups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        state.loading = false;
      }
    });
    // When our request is rejected:
    builder.addCase(postNewGroup.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ GROUPS ==========================
    // When our request is pending:
    builder.addCase(readAllGroups.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAllGroups.fulfilled, (state, action) => {
      state.groups = action.payload.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAllGroups.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ GROUP ==========================
    // When our request is pending:
    builder.addCase(readGroup.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readGroup.fulfilled, (state, action) => {
      state.groupSelected = action.payload;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readGroup.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== UPDATE GROUP ==========================
    // When our request is pending:
    builder.addCase(updateGroupNameById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(updateGroupNameById.fulfilled, (state, action) => {
      state.groups = state.groups.map((group) => {
        if (group?.id === action.payload?.id) {
          return action.payload;
        }

        return group;
      });
      state.groupSelected = action.payload;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(updateGroupNameById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== DELETE GROUP ==========================
    // When our request is pending:
    builder.addCase(removeGroupById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(removeGroupById.fulfilled, (state, action) => {
      state.groups = state.groups.filter((group) => group.id !== action.payload);
      state.groupSelected = null;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(removeGroupById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default groupSlice.reducer;
