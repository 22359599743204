import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorsState } from '.';

const setStatusCode = (state: ErrorsState, action: PayloadAction<AxiosResponse>): void => {
  state.error = action.payload;
};

const reducers = {
  setStatusCode,
};

export default reducers;
