import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, List } from '@mui/material';
import { SECTIONS_AND_CONTENTS } from '../../utils/constants/sections';
import styles from './SideMainMenu.module.scss';
import MenuSection from './components/MenuSection';

const SideMainMenu: FC = () => (
  <nav className={styles.side_main_menu}>
    <Box className={styles.frame}>
      <List className={styles.section_container} sx={{ padding: 0 }}>
        {SECTIONS_AND_CONTENTS.map(({ options, title }) => (
          <MenuSection key={`${title}-${uuidv4()}`} options={options} title={title} />
        ))}
      </List>
    </Box>
  </nav>
);

export default SideMainMenu;
