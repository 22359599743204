import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { setSelectedItem } from '../../../store/features/menuSlice';
import {
  accentBlue,
  backgroundFocusBlue,
  paragraphBaseline,
  primaryBlue,
  focusPurple,
} from '../../../styles/partials/variables';
import { useAppDispatch } from '../../../utils/hooks/storeHooks';
import Icon from '../../icon/Icon';

type ListIndexProps = {
  indexName: string;
  url: string;
};

const ListIndex = ({ indexName, url }: ListIndexProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Getting the current path
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[1].toLowerCase();
  // Checking if the index is selected according to the current path
  const isSelected = currentPath === url;
  // Setting some styles depending on isSelected
  const fillColor = isSelected ? primaryBlue : paragraphBaseline;
  const iconName = indexName.split(' ').join('-').toLowerCase();

  const handleOnClick = () => {
    dispatch(setSelectedItem(indexName));
    navigate(`/${url}`);
  };

  return (
    <ListItemButton
      disableRipple
      selected={isSelected}
      sx={{
        borderRadius: '4px',
        marginTop: '1px',
        pb: 0,
        pl: 3,
        pt: 0,
        '&.Mui-selected': {
          backgroundColor: backgroundFocusBlue,
          color: backgroundFocusBlue,
        },
        '&.Mui-selected:hover': {
          backgroundColor: accentBlue,
        },
        '&.Mui-selected:focus-visible': {
          backgroundColor: backgroundFocusBlue,
        },
        '&.Mui-focusVisible': {
          backgroundColor: backgroundFocusBlue,
          outline: `2px solid ${focusPurple}`,
          borderRadius: '4px',
        },
        ':hover': {
          backgroundColor: accentBlue,
        },
      }}
      onClick={handleOnClick}
    >
      <ListItemIcon sx={{ minWidth: '1.5rem' }}>
        <Icon icon={iconName} isSelected={isSelected} />
      </ListItemIcon>

      <ListItemText>
        <Typography
          sx={{
            color: fillColor,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '1.5rem',
            height: '3.84615384615%',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.002em',
          }}
        >
          {indexName}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};

export default ListIndex;
