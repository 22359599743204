import { Dayjs } from 'dayjs';
import { ServiceOptions } from 'services/baseService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { audienceBackgroundService } from '../../services/audienceBackgroundService';
import { backgroundService } from '../../services/backgroundsService';
import { AudienceBackground, Background } from '../../type/background';

type BackgroundState = {
  error?: string | unknown;
  backgrounds: Background[];
  groupBackgrounds: Background[];
  individualBackgrounds: Background[];
  backgroundSelected?: Background | null;
  loading: boolean;
};

const initialState: BackgroundState = {
  backgrounds: [],
  groupBackgrounds: [],
  individualBackgrounds: [],
  loading: false,
};

export const postNewBackground = createAsyncThunk<
  Background,
  {
    name: string;
    image: number;
    status: 'scheduled' | 'draft' | 'published';
    audience: number[] | string[];
    duration: number;
    date?: Dayjs;
    checksum: string;
  }
>(
  'background/postNewBackground',
  async (
    req: {
      name: string;
      image: number;
      status: 'scheduled' | 'draft' | 'published';
      audience: number[] | string[];
      duration: number;
      date?: Dayjs;
      checksum: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { checksum, date, duration, image, name, status } = req;
      const { data: newBackground } = await backgroundService.create({
        name,
        image,
        status,
        duration,
        date: date?.format('YYYY-MM-DD'),
        checksum,
      });

      await Promise.all(
        req.audience.map(async (audience) =>
          audienceBackgroundService.createNewAudienceBackground(newBackground.id, audience),
        ),
      );

      const options: ServiceOptions = {
        include: [{ AudienceBackground: ['Group'] }],
      };
      const { data: newBackgroundData } = await backgroundService.getById(newBackground.id, options);

      return newBackgroundData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readAllBackgrounds = createAsyncThunk<Background[]>(
  'background/readAllBackgrounds',
  async (_: void, { rejectWithValue }) => {
    try {
      const options: ServiceOptions = {
        include: [{ AudienceBackground: ['Group'] }],
        where: { is_active: true },
        order: [['date', 'ASC']],
      };
      const { data } = await backgroundService.getAll(options);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readBackgroundById = createAsyncThunk<Background, number>(
  'background/readBackgroundById',
  async (backgroundId: number, { rejectWithValue }) => {
    try {
      const options: ServiceOptions = {
        include: [{ AudienceBackground: ['Group'] }],
      };
      const { data } = await backgroundService.getById(backgroundId, options);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeBackgroundById = createAsyncThunk<number, number>(
  'background/removeBackgroundById',
  async (id: number, { rejectWithValue }) => {
    try {
      await backgroundService.delete(id);

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateBackground = createAsyncThunk<
  Background,
  {
    id: number;
    image: number;
    name: string;
    audience: number[] | string[];
    currentAudience: AudienceBackground[];
    status: string;
    duration: number;
    date?: Dayjs;
    checksum: string;
  }
>(
  'background/updateBackground',
  async (
    req: {
      id: number;
      image: number;
      name: string;
      audience: number[] | string[];
      currentAudience: AudienceBackground[];
      status: string;
      duration: number;
      date?: Dayjs;
      checksum: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { checksum, date, duration, image, name, status } = req;
      await backgroundService.patch(req.id, {
        name,
        image,
        duration,
        date: date?.format('YYYY-MM-DD'),
        checksum,
        status,
      });

      let audiencesToAdd = [...req.audience];
      const byGroup = typeof req.audience[0] === 'number';
      await Promise.all(
        req.currentAudience.map(async ({ fullName, groupId, id }) => {
          const isAudienceNotIncluded = !req.audience.join(', ').includes(groupId ? groupId.toString() : fullName);
          if (isAudienceNotIncluded) {
            return audienceBackgroundService.delete(id);
          }
          audiencesToAdd = audiencesToAdd.filter((audience) => audience !== (byGroup ? groupId : fullName));
          return false;
        }),
      );

      await Promise.all(
        audiencesToAdd.map(async (audience) => audienceBackgroundService.createNewAudienceBackground(req.id, audience)),
      );

      const options: ServiceOptions = {
        include: [{ AudienceBackground: ['Group'] }],
      };
      const { data: updatedBackground } = await backgroundService.getById(req.id, options);

      return updatedBackground;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const backgroundSlice = createSlice({
  name: 'background',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearSelectedBackground: (state) => {
      state.backgroundSelected = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== CREATE NEW BACKGROUND ==========================
    // When our request is pending:
    builder.addCase(postNewBackground.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(postNewBackground.fulfilled, (state, action) => {
      state.backgrounds.push(action.payload);
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(postNewBackground.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ ALL BACKGROUNDS ==========================
    // When our request is pending:
    builder.addCase(readAllBackgrounds.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAllBackgrounds.fulfilled, (state, action) => {
      state.backgrounds = action.payload;
      const groupBg: Background[] = [];
      const individualBg: Background[] = [];
      action.payload.forEach((background) => {
        if (background.AudienceBackground.length > 0) {
          if (background.AudienceBackground[0].groupId) {
            groupBg.push(background);
          } else {
            individualBg.push(background);
          }
        }
      });
      state.groupBackgrounds = groupBg;
      state.individualBackgrounds = individualBg;
      state.backgroundSelected = null;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAllBackgrounds.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ BACKGORUND BY ID ==========================
    // When our request is pending:
    builder.addCase(readBackgroundById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readBackgroundById.fulfilled, (state, action) => {
      state.backgroundSelected = action.payload;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readBackgroundById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== DELETE BACKGROUND ==========================
    // When our request is pending:
    builder.addCase(removeBackgroundById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(removeBackgroundById.fulfilled, (state, action) => {
      state.backgrounds = state.backgrounds.filter((background) => background.id !== action.payload);
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(removeBackgroundById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== UPDATE BACKGROUND ==========================
    // When our request is pending:
    builder.addCase(updateBackground.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(updateBackground.fulfilled, (state, action) => {
      state.backgrounds = state.backgrounds.map((background) => {
        if (background.id === action.payload.id) {
          return action.payload;
        }

        return background;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(updateBackground.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const { clearSelectedBackground } = backgroundSlice.actions;

export default backgroundSlice.reducer;
