/* eslint-disable camelcase */
import { useState, useEffect, FC, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AudienceType } from 'utils/constants/audienceType';
import { ReactComponent as BackIcon } from '../../assets/BackArrow.svg';
import { BlueAddBtn } from '../../components/blueAddBtn/BlueAddBtn';
import EditModal from '../../components/editModal/EditModal';
import { SearchBar } from '../../components/searchBar/SearchBar';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { StyledMembersTable } from '../../components/tableList/membersTable/StyledMembersTable';
import { readGroup } from '../../store/features/groupSlice';
import { readMembersByGroupId } from '../../store/features/memberSlice';
import { primaryBlue } from '../../styles/partials/variables';
import { GroupMember } from '../../type/group';
import { toPascalCase } from '../../utils/formatters/text';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from './EditGroup.module.scss';

export const EditGroup: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const dispatch = useAppDispatch();
  const groupName = useAppSelector((state) => state.group.groupSelected?.name);
  const users = useAppSelector((state) => state.member.members);

  // Hook to grab the path's params that we're interested on, in this case the id of the group to edit
  const params = useParams();
  const groupId = Number(params.groupId);

  // Filters the Users array inside the table, user by user. Returns the users that match the search value
  const tableSearch = (Users: Array<GroupMember>) =>
    Users.filter(
      ({ full_name, member_email }: GroupMember) =>
        full_name.toLowerCase().includes(searchValue) || member_email.toLowerCase().includes(searchValue),
    );

  // Updates the users array (to be passed into the table search) every time a new input value is entered into the search bar
  useEffect(() => {
    dispatch(readMembersByGroupId(groupId));
  }, [searchValue, groupId, dispatch]);

  // Updates the group name once it was successfully edited
  useEffect(() => {
    dispatch(readGroup(groupId));
  }, [groupId, dispatch]);

  return (
    <>
      <div className={styles.editContainer}>
        <aside>
          <Link className={styles.backLink} to='/groups'>
            <BackIcon fill={primaryBlue} /> <span>&emsp;</span> Back
          </Link>
        </aside>
        <main className={styles.mainContainer}>
          <header>
            <h2 className={styles.viewTitle}>
              <Fragment>
                Group: {groupName && toPascalCase(groupName)} &nbsp;
                <EditModal actionLabel='Save Group Name' crudId={groupId} label={AudienceType.group} />
              </Fragment>
            </h2>
            <section className={styles.viewHeader}>
              {/* The callback allows us to acces the search bar's input inner query/value, to set it out here for the other components */}
              <SearchBar placeholderTxt='Search by name or email' searchQuery={(val) => setSearchValue(val)} />
              {/* This button allows to add members to the current group by opening a modal */}
              <BlueAddBtn actionLabel='Add New Member' groupId={groupId} />
            </section>
          </header>
          {/* The data prop searches the table with the query/value entered in the search bar component */}
          <StyledMembersTable count={tableSearch(users).length} crudId={groupId} data={tableSearch(users)} />
        </main>
      </div>

      <SimpleSnackbar />
    </>
  );
};
