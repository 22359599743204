import { useLocation, Navigate } from 'react-router-dom';
import { selectIsAuth } from 'store/session/selectors';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { Box } from '@mui/material';

type PrivateRouteProps = {
  children?: JSX.Element;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const location = useLocation();
  const isAuthenticatedFromStore = useAppSelector(selectIsAuth);
  const isAuthenticated = process.env.REACT_APP_DEVELOPMENT_MODE === 'true' || isAuthenticatedFromStore;

  return isAuthenticated ? <Box>{children}</Box> : <Navigate replace state={{ from: location }} to='/login' />;
};

export default PrivateRoute;
