import { ServiceOptions } from 'services/baseService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userService } from '../../services/usersService';
import { AdminState } from '../../type/admin';
import { User } from '../../type/user';

const initialState: AdminState = {
  admins: [],
  loading: false,
};

export const postNewAdmin = createAsyncThunk<User[], string[]>(
  'admin/postNewAdmin',
  async (names: string[], { rejectWithValue }) => {
    try {
      const newAdmins: User[] = [];
      await Promise.all(
        names.map(async (name) => {
          const { data: newAdmin } = await userService.createNewAdmin({ value: name });
          newAdmins.push(newAdmin);
          return newAdmin;
        }),
      );

      return newAdmins;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readAllAdmins = createAsyncThunk<User[]>('user/readAllAdmins', async (_: void, { rejectWithValue }) => {
  try {
    const options: ServiceOptions = {
      include: [{ model: 'UserRole', where: { roleId: 1 }, required: 'true' }],
    };
    const { data } = await userService.fetchAllAdmins(options);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeAdminById = createAsyncThunk<number, number>(
  'admin/removeAdminById',
  async (adminId: number, { rejectWithValue }) => {
    try {
      await userService.deleteAdminById(adminId);

      return adminId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    // ========================== CREATE NEW ANNOUNCEMENT ==========================
    // When our request is pending:
    builder.addCase(postNewAdmin.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(postNewAdmin.fulfilled, (state, action) => {
      action.payload.forEach((element) => {
        state.admins.push(element);
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(postNewAdmin.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });

    // ========================== READ ALL ADMINS ==========================
    // When our request is pending:
    builder.addCase(readAllAdmins.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAllAdmins.fulfilled, (state, action) => {
      state.admins = action.payload.sort((adminA, adminB) => {
        if (adminA.firstName < adminB.firstName) {
          return -1;
        }
        if (adminA.firstName > adminB.firstName) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAllAdmins.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });

    // ========================== DELETE ADMIN ==========================
    // When our request is pending:
    builder.addCase(removeAdminById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(removeAdminById.fulfilled, (state, action) => {
      state.admins = state.admins.filter((admins) => admins.id !== action.payload);
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(removeAdminById.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
  },
});

export default adminSlice.reducer;
