import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import snackContext from 'store/features/contextSnackbar';
import { setHasError, setIsLoading } from 'store/session';
import { SSOLoginStatus, SSOErrorMessage } from 'utils/constants/sso';
import { Box, Typography, Button, ThemeProvider, Checkbox } from '@mui/material';
import { ReactComponent as CheckboxIcon } from '../../assets/Checkbox.svg';
import { ReactComponent as CheckboxOutlineIcon } from '../../assets/CheckboxOutline.svg';
import TKGLogo from '../../assets/TKGLogo.png';
import loginImg from '../../assets/loginImg.png';
import { ReactComponent as MSLogo } from '../../assets/ms-symbol.svg';
import { azulDesign } from '../../components/simpleComponents/AzulDesignTheme';
import AzulInput from '../../components/simpleComponents/AzulInput';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { selectIsAuth } from '../../store/session/selectors';
import { logInWithExchange } from '../../store/session/thunks';
import { primaryHoverBlue2, backgroundFocusBlue, backgroundWhite } from '../../styles/partials/variables';
import { useAppDispatch } from '../../utils/hooks/storeHooks';

const Login = () => {
  const { setSnackPack } = useContext(snackContext);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuth);
  const dispatch = useAppDispatch();
  const [hasSSOError, setHasSSOError] = useState(false);
  const [ssoErrorMessage, setSsoErrorMessage] = useState('');

  // Redirects to the MS login page, for picking and account
  const handleLoginMs = () => {
    setHasSSOError(false);
    dispatch(setIsLoading(true));
    window.location.replace(`${API_BASE_URL}/microsoftauth/login?platform=admin`);
  };

  // Reset session error state on load
  useEffect(() => {
    dispatch(setHasError(false));
  }, [dispatch]);

  // Handle SSO callback success
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token !== null) {
      // Get correct auth credentials and redirect to dashboard
      dispatch(logInWithExchange(token));
    }
  }, [location, dispatch]);

  // Handle SSO callback failures
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    if (status !== null) {
      switch (status) {
        case SSOLoginStatus.NotFound:
          setHasSSOError(true);
          setSsoErrorMessage(SSOErrorMessage.NotFound);
          break;
        case SSOLoginStatus.Unauthorized:
          setHasSSOError(true);
          setSsoErrorMessage(SSOErrorMessage.Unauthorized);
          break;
        default:
          setHasSSOError(true);
          setSsoErrorMessage(`Unknown error: ${status}`);
          break;
      }
    }
  }, [location]);

  // Triggers a toast notification in case of an error
  useEffect(() => {
    if (hasSSOError && ssoErrorMessage) {
      setSnackPack((prev) => [
        ...prev,
        { customErrorMessage: ssoErrorMessage, message: '', successful: false, key: new Date().getTime() },
      ]);
    }
  }, [hasSSOError, setSnackPack, ssoErrorMessage]);

  // Redirects the user to the home page once it has been successfully authenticated
  if (isAuthenticated) {
    return <Navigate to='/' />;
  }

  return (
    <ThemeProvider theme={azulDesign}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '0.75fr 1fr',
          height: '100vh',
          justifyContent: 'center',
          margin: '0',
          width: '100vw',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: backgroundWhite,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0',
          }}
        >
          <img alt='The Ksquare Group Logo' src={TKGLogo} style={{ margin: '-1.5rem 0 3.75rem 0' }} width='50%' />
          <img alt='Dashground Login' src={loginImg} width='67%' />
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: backgroundFocusBlue,
            display: 'flex',
            justifyContent: 'center',
            margin: '0',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '0',
              width: '282px',
            }}
          >
            <Typography variant='loginHeader'>Welcome to</Typography>
            <Typography
              sx={{
                fontSize: '2rem',
                lineHeight: '40px',
                marginBottom: '24px',
              }}
              variant='loginHeader'
            >
              Dash<span style={{ color: primaryHoverBlue2 }}>ground</span>
            </Typography>
            <AzulInput label='Email' margin='0.75rem' placeholder='Email' type='email' width='100%' />
            <AzulInput label='Password' margin='0.75rem' placeholder='Password' type='password' width='100%' />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: '1rem 0',
                width: '100%',
              }}
            >
              <Typography variant='paragraph12'>
                {' '}
                <Checkbox
                  checkedIcon={<CheckboxIcon />}
                  disableRipple
                  icon={<CheckboxOutlineIcon />}
                  sx={{ padding: '0 5px 2px 3px' }}
                />{' '}
                Remember Me
              </Typography>
              <a aria-label='forgot password?' href='https://www.google.com/'>
                <Typography variant='link'>Forgot password?</Typography>
              </a>
            </Box>
            <Button fullWidth sx={{ margin: '1rem 0' }} variant='azulPrimary'>
              Login
            </Button>
            <Typography margin='1.5rem 0' variant='paragraph12'>
              Or join with your existing account
            </Typography>
            <Button startIcon={<MSLogo />} variant='microsoftLogin' onClick={handleLoginMs}>
              Sign in with Microsoft
            </Button>
          </Box>
        </Box>
      </Box>
      <SimpleSnackbar />
    </ThemeProvider>
  );
};
export default Login;
