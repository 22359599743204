/**
 * Defines the event key names for determining which keyboard key was pressed
 * @type {{SPACE: string, ARROW_DOWN: string, ARROW_RIGHT: string, ENTER: string, ARROW_UP: string, ARROW_LEFT: string, ESCAPE: string}}
 */

export const KEY = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
  ARROW_DOWN: 'ArrowDown',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_UP: 'ArrowUp',
  TAB: 'Tab',
};
