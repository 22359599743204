import React, { forwardRef } from 'react';
import { KEY } from '../../utils/constants/keyboard';
import styles from './icon.module.scss';
import iconPath from './iconSVGMapper';
import { IconProps } from './types';

const Icon = forwardRef<HTMLInputElement, IconProps>(
  (
    {
      className = '',
      color,
      disabled = false,
      fillRule = 'evenodd',
      icon,
      isSelected = false,
      onClick,
      role,
      size,
      title,
      // viewBox = '0 0 16 16',
      ariaLabel,
      onBlur,
      onKeyPress,
    },
    ref,
  ) => {
    const handleClick = (event?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (!disabled) {
        onClick?.(event);
      }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
      if (onKeyPress) {
        onKeyPress(event);
        return;
      }

      if (event.key === KEY.ENTER || event.key === KEY.SPACE) {
        event.preventDefault();
        handleClick();
      }
    };

    return (
      <span
        aria-label={ariaLabel || title}
        className={`${styles.iconContainer} ${className}`}
        ref={ref}
        style={size ? { width: `${size}rem`, height: `${size}rem` } : {}}
        title={title}
        onBlur={onBlur}
      >
        <svg
          className={`${styles.icon} ${disabled && styles.disabled} ${isSelected && styles.selected}`}
          role={role}
          tabIndex={onClick && !disabled ? 0 : undefined}
          viewBox={iconPath[icon].viewBox}
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          onClick={handleClick}
          onKeyDown={handleKeyPress}
        >
          <path d={iconPath[icon].path} fillRule={fillRule} style={{ fill: color }} />
        </svg>
      </span>
    );
  },
);

Icon.displayName = 'Icon'; // To resolve ES-Lint no display name error

export default Icon;
